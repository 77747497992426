import React from 'react'
import { Box, CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <Box sx={{display:"flex",justifyContent:"center",p:20}}>
    <CircularProgress/>
    </Box>
  )
}

export const Loading2 = () => {
  return (
    <Box sx={{display:"flex",justifyContent:"center",p:5}}>
    <CircularProgress/>
    </Box>
  )
}