import React from "react";
import {
    alpha,
    Box,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import ReversedProgressBar from "../../ProgressBars/ReversedProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import Progressbar from "../../Charts/DemoCharts/Progressbar";
function SFBrandInteraction(){
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontWeight:theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Shurooq SF",
            sourcing: 1.46,
            pricing:14,
            name2: "Afia Corn",
            sourcing2: 2.39,
            pricing2:21,
        },      
        {
            name: "Afia Corn",
            sourcing: 1.50,
            pricing:13,
            name2: "Afia Corn Plus",
            sourcing2: 2.71,
            pricing2:13,
        },
        {
            name: "Shams SF",
            sourcing: 1.25,
            pricing:12,
            name2: "Shams SF",
            sourcing2: 1.27,
            pricing2:13,
        },
        {
            name: "Halah SF",
            sourcing: 0.63,
            pricing:11,
            name2: "Hayat",
            sourcing2: 0.57,
            pricing2:8,
        
        },
        {
            name: "Nakheel",
            sourcing: 0.99,
            pricing:10,
            name2: "Halah SF",
            sourcing2: 0.43,
            pricing2:8,
        },
        {
            name: "Hayat ",
            sourcing: 0.63,
            pricing:9,
            name2: "Shurooq SF ",
            sourcing2: 0.78,
            pricing2:8,
        },

  

    ];


    return (
        <>
          <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Afia Sunflower Brand Interaction</Typography>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sourcing vs. Fair Share</StyledTableCell>
                    <StyledTableCell>Pricing (-25%)</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Pricing (+25%)</StyledTableCell>
                    <StyledTableCell>Sourcing vs. Fair Share</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((item, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell>{item.sourcing}</StyledTableCell>
                      <StyledTableCell>
                        <Box>
                        <Progressbar progress={item.pricing } color='#008FFB' /> {item.pricing}%
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>{item.name2}</StyledTableCell>
                      <StyledTableCell>
                      <Box>
                        <Progressbar progress={item.pricing2} color='#ff4560' /> {item.pricing2}%
                      </Box>
                      </StyledTableCell>
                      <StyledTableCell>{item.sourcing2}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </>
      );
}
export default SFBrandInteraction;