import ReactApexChart from "react-apexcharts";

function StackedProgressBar(){

    const series= [{
        name: 'Marine Sprite',
        data: [44,]
      }, {
        name: 'Striking Calf',
        data: [53,]
      }, {
        name: 'Tank Picture',
        data: [12]
      }, {
        name: 'Bucket Slope',
        data: [9,]
      }, {
        name: 'Reborn Kid',
        data: [25,]
      }];
    const  options={
        chart: {
          type: 'bar',
        //   height: 40,
          stacked: true,
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '20%',
            endingShape: 'rounded',
            
            barHeight: '100%',
            borderRadius: 6,
            // distributed: true,
            fontSize:8
            // dataLabels: {
            //   total: {
            //     enabled: true,
            //     offsetX: 0,
            //     style: {
            //       fontSize: '13px',
            //       fontWeight: 900
            //     }
            //   }
            // }
          },
        },
        grid:{
            show: false,
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },

        xaxis: {
            labels: {
              show: false, // This hides the x-axis labels
            }
          },
        yaxis: {
            show:false,
            axisTicks: {
                show: false, // This hides the tick marks
            },
            axisBorder: {
                show: false, // This hides the axis border line
            }
        },
        fill: {
          opacity: 1
        },
        legend: {
            show: false,
        }
      };
    
    



    return(         
    <ReactApexChart 
    options={options} 
    height={60}
    series={series} 
    type="bar"  />
    );
}
export default StackedProgressBar;