import React from "react";
import { alpha } from "@mui/material";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import MultiProgressBar from "../../ProgressBars/MultiProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import ReversedProgressBar from "../../ProgressBars/ReversedProgressBar";
import StackedProgressBar from "../../ProgressBars/StackedProgressBar";
function BrandShare(){
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Brand 1",
            brandValueIdx: 0.2,
            tradeVbrand: 1.7,
            rgmIdx: 0.2,
            alamieName: 3,
            lamarName: 1,
            alamie: 0.428571429,
            lamar: 0.142857143,
            p: 1,
            p12: 50,
        },
        {
            name: "Brand 2",
            brandValueIdx: 0.1,
            tradeVbrand: 1.6,
            rgmIdx: 0.9,
            alamieName: 2,
            lamarName: 1,
            alamie: 0.628571429,
            lamar: 0.242857143,
            p: 50,
            p12: 21,
        },
        {
            name: "Brand 3",
            brandValueIdx: 0.1,
            tradeVbrand: 9.6,
            rgmIdx: 0.1,
            alamieName: 1,
            lamarName: 3,
            alamie: 0.428571429,
            lamar: 0.542857143,
            p: 1,
            p12: 5,
        },
        {
            name: "Brand 4",
            brandValueIdx: 0.3,
            tradeVbrand: 86,
            rgmIdx: 0.3,
            alamieName: 4,
            lamarName: 1,
            alamie: 0.458571429,
            lamar: 0.144857143,
            p: 5,
            p12: 4,
        },
    ];
    return(
        <>
        <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Brand Share By Size / Price Bracket</Typography>
        <TableContainer sx={{ height: 200 }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Size</StyledTableCell>
                        <StyledTableCell>P12M Value Share (%)</StyledTableCell>
                        <StyledTableCell>Brand WoB (%)</StyledTableCell>
                        <StyledTableCell>Brand Sales IYA (%)</StyledTableCell>
                        <StyledTableCell>Brand Price Index</StyledTableCell>
                        <StyledTableCell>Comparison Across Brands</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((a, idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell>{idx}</StyledTableCell>
                            <StyledTableCell>{a.p12}</StyledTableCell>
                            <StyledTableCell>
                                <ReversedProgressBar
                                 bgcolor= {alpha("#BF83FF",0.6)}
                                 progressChild={a.p12 * 10}
                                 number={a.p12}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                            <ProgressBar
                            bgcolor= {alpha("#FA5A7D",0.6)}
                            progressChild={a.p12 * 10}
                            number={a.p12 } />
                            </StyledTableCell>
                            <StyledTableCell>
                            <ColoredBox number={a.brandValueIdx} Color={theme.palette.primary.light} />
                            </StyledTableCell>
                            <StyledTableCell>
                            <ColoredBox number={a.rgmIdx} Color={theme.palette.accent.light} />
                            </StyledTableCell>
                            <StyledTableCell>
                                <StackedProgressBar/>
                            </StyledTableCell>
                            
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}
export default BrandShare;