import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import Progressbar from "../../Charts/DemoCharts/Progressbar";
function PricingStrategy() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      padding: "5px",
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize.xxsmall,
      textAlign: "center",
      justifyContent: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      color: theme.palette.primary.dark,
      fontSize: 10,
      textAlign: "center",
      padding: "3px",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // Reduce the row height
    height: 30,
  }));

  const list = [
    {
      name: "Afia Corn 1.5L",
      cannibilization: 81,
      topSKU: "Nasreen 17L",
      strategy: "Uptiering within brand",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia Corn 1.5x2L + 0.5",
      cannibilization: 80,
      topSKU: "Afia SF 1.5x2L",
      strategy: "Uptiering from corn",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia Corn 2.9L",
      cannibilization: 81,
      topSKU: "Afia Corn Plus 1.5x2L",
      strategy: "Uptiering within brand",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia Corn 9L",
      cannibilization: 79,
      topSKU: "Afia Corn Plus 2.9L",
      strategy: "Uptiering within brand",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia Corn 0.75L",
      cannibilization: 67,
      topSKU: "Noor SF 1.5L",
      strategy: "Penetration within segment",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia Corn 1.5x2L",
      cannibilization: 68,
      topSKU: "Afia SF 1.5x2L",
      strategy: "Uptiering from brand",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia SF 1.5L",
      cannibilization: 78,
      topSKU: "Afia SF 1.5x2L",
      strategy: "Uptiering from lower size",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia SF 1.5x2L",
      cannibilization: 75,
      topSKU: "Afia SF 2.9L",
      strategy: "Uptiering from lower size",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
    {
      name: "Afia SF 2.9L",
      cannibilization: 79,
      topSKU: "Afia SF 1.5x2L",
      strategy: "Uptiering from lower size",
      volume: true,
      value: false,
      min: "",
      max: "",
    },
  ];
  return (
    <>
      <Typography fontWeight={theme.typography.fontWeightBold} p={1}>
        Pricing Strategy Per SKU
      </Typography>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>SKU Name</StyledTableCell>
              <StyledTableCell>Cannibilization</StyledTableCell>
              <StyledTableCell>Top SKU in Interaction</StyledTableCell>
              <StyledTableCell>Strategy</StyledTableCell>
              <StyledTableCell>Volume Driver</StyledTableCell>
              <StyledTableCell>Value Driver</StyledTableCell>
              <StyledTableCell>Min Price</StyledTableCell>
              <StyledTableCell>Max Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => (
              <StyledTableRow key={idx}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Progressbar
                      progress={item.cannibilization}
                      color="#ff4560"
                    />
                    {item.cannibilization}%
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{item.topSKU}</StyledTableCell>
                <StyledTableCell>{item.strategy}</StyledTableCell>
                <StyledTableCell>
                  {item.volume ? <CheckIcon /> : null}
                </StyledTableCell>
                <StyledTableCell>
                  {item.value ? <CheckIcon /> : null}
                </StyledTableCell>
                <StyledTableCell>{item.min}</StyledTableCell>
                <StyledTableCell>{item.max}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default PricingStrategy;
