import axios from "axios";

const axiosClient = axios.create({
  //aseURL: `https://corsproxy.io/?http://simulatorbackend-env.eba-3itakpmk.eu-west-3.elasticbeanstalk.com`,

  // baseURL: `http://simulator-env.eba-v2p3p26w.eu-west-3.elasticbeanstalk.com`,
  baseURL: "https://cors-proxy.mahmoud-m.workers.dev/?url=http://rgm-env.eba-s9jtimap.eu-west-3.elasticbeanstalk.com",
  //baseURL: `http://127.0.0.1:5000`,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76',
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default axiosClient;