import React from "react";
import {
    alpha,
    Box,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import ReversedProgressBar from "../../ProgressBars/ReversedProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import Progressbar from "../../Charts/DemoCharts/Progressbar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
function CornBrandInteraction(){
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontWeight:theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Afia SF",
            sourcing: 2.89,
            pricing:22,
            name2: "Afia Corn Plus",
            sourcing2: 4.89,
            pricing2:24,
        },      
        {
            name: "Afia Corn Plus",
            sourcing: 3.49,
            pricing:17,
            name2: "Afia Sunflower",
            sourcing2: 3.15,
            pricing2:24,
        },
        {
            name: "Al Arabi",
            sourcing: 1.58,
            pricing:9,
            name2: "Al Arabi",
            sourcing2: 1.72,
            pricing2:10,
        },
        {
            name: "Hayat",
            sourcing: 0.65,
            pricing:9,
            name2: "Hayat",
            sourcing2: 0.47,
            pricing2:6,
        
        },
        {
            name: "Halah SF",
            sourcing: 0.40,
            pricing:7,
            name2: "Shams SF",
            sourcing2: 0.62,
            pricing2:6,
        },
        {
            name: "Nakheel",
            sourcing: 0.62,
            pricing:7,
            name2: "Halah SF ",
            sourcing2: 0.28,
            pricing2:5,
        },

  

    ];


    return (
        <>
          <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Afia Corn Brand Interaction</Typography>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sourcing vs. Fair Share</StyledTableCell>
                    <StyledTableCell>Pricing (-25%)</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Pricing (+25%)</StyledTableCell>
                    <StyledTableCell>Sourcing vs. Fair Share</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((item, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell>
                      <ColoredBox  number={item.sourcing} Color="#ff4560"/>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box>
                        <Progressbar progress={item.pricing }  color='#ff4560'/> {item.pricing}%
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>{item.name2}</StyledTableCell>
                      <StyledTableCell>
                      <Box>
                        <Progressbar progress={item.pricing2} color='#00E396' /> {item.pricing2}%
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell><ColoredBox  number={item.sourcing2} Color="#00E396"/></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </>
      );
}
export default CornBrandInteraction;