import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useDroppable } from "@dnd-kit/core";
import DraggableCard from "./DraggableCard";
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";

function DroppableGrid({ id, children = [], RemoveComponent }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  if (!children || !Array.isArray(children)) {
    console.error("DroppableGrid: `items` is not an array");
    return null; // You can return a fallback UI here
  }

  const style = {
    backgroundColor: isOver ? "lightblue" : "transparent",
  };

  return (
    <SortableContext id={id} items={children} strategy={rectSortingStrategy}>
      <div ref={setNodeRef} style={style}>
        <Grid container spacing={2}>
          {children.length > 0 ? (
            children.map((child) => (
              <Grid item xs={child.xs} md={child.md} key={child.id}>
                <DraggableCard
                  id={child.id}
                  child={child}
                  RemoveComponent={RemoveComponent}
                />
              </Grid>
            ))
          ) : (
            <Container>
              <Typography>Drag Items Here</Typography>
            </Container>
          )}
        </Grid>
      </div>
    </SortableContext>
  );
}

export default DroppableGrid;
