import { Box, Typography, Paper, Grid, Divider } from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";

function GapsAndSolutionBox({ Chart }) {
    const TitleStyle = {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize.medium,
        paddingX: 1,
        color: theme.palette.primary.main,
    };

    const TextStyle = {
        padding: 1,
        fontSize: theme.typography.fontSize.xxsmall,
    };

    return (
      
        <>
            <Grid container sx={{ minHeight: 270, maxHeight: 560 }}>
                <Grid item xs={12} md={6} >
                    {Chart}
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Box>
                        <Typography sx={TitleStyle}>Gaps</Typography>
                        <Typography sx={TextStyle}>
                            Mfc is lacking growth in the “Fried Finger Fish” segment by x%.
                            Competitor Y is growing on Mfc brand expense. Impact is (xx%) of decline.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={TitleStyle}>Solutions</Typography>
                        <Typography sx={TextStyle}>
                            Product perception:
                            Pricing: the price index of mfc brand versus its anchor brand increased above the threshold of 115.
                            Optimize price index to be 100 to 110 of brand Y. Promotion:
                            Place (Channels):
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default GapsAndSolutionBox;
