import { Box, Typography, Paper, Grid } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../Theme/index";
import LineChart from "../../Components/Charts/LineChart/LineChart";
import GapsAndSolutionBox from "../../Components/Boxes/GapsAndSolutionBox/GapsAndSolutionBox";
import ThreePieChart from "../../Components/Charts/ThreePieChart/ThreePieChart";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";
import DroppableGrid from "../../DragAndDrop/DroppableGrid";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  handleDragStart,
  handleDragOver,
  handleDragEnd,
  findContainer,
} from "../../DragAndDrop/DragHandlers";
import TopProducts from "../../Components/Tables/TopProductsTable/TopProducts";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import UnknownMixChart from "../../Components/Charts/UnknownMixChart/UnknownMixChart";
function GapsAndSolutions() {
  function RemoveComponent(id) {
    setItems((prevItems) => {
      const itemToMove = prevItems.mainDash.find((item) => item.id === id);
      const updatedMainDash = prevItems.mainDash.filter(
        (item) => item.id !== id
      );
      const updatedAddSection = [...prevItems.addSection, itemToMove];

      return {
        ...prevItems,
        mainDash: updatedMainDash,
        addSection: updatedAddSection,
      };
    });
  }
  const [activeId, setActiveId] = useState();
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
  };

  const TextStyle = {
    padding: 1,
    fontSize: theme.typography.fontSize.xxsmall,
  };

  const [items, setItems] = useState({
    addSection: [
      {
        id: "LineChart",
        component: (
          <GapsAndSolutionBox Chart={<LineChart Title={"Volume Share"} />} />
        ),
        name: "Volume Share",
        xs: 12,
        md: 9,
      },
      {
        id: "PieChart",
        component: (
          <ThreePieChart Title={"Volume Share"} Sign={"%"} Color={"#7735FD"} />
        ),
        name: "Volume Share",
        xs: 12,
        md: 3,
      },
    ],
    mainDash: [
      {
        id: "TopProducts",
        component: <TopProducts />,
        name: "Top Products",
        xs: 12,
        md: 12,
      },
    ],
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const toggleAddSection = () => {
    setIsAddSectionOpen(!isAddSectionOpen);
  };
  const handleDragStartWrapper = (event) => handleDragStart(event, setActiveId);
  const handleDragOverWrapper = (event) =>
    handleDragOver(event, items, setItems, findContainer);
  const handleDragEndWrapper = (event) =>
    handleDragEnd(event, items, setItems, setActiveId, findContainer);

  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };
  const BoxStyle = {
    display: "flex",
    flexDirection: "column",
    height: 200,
  };

  const firstGapData = [
    {
      name: "Volume Sales Y22",
      type: "column",
      data: [186570, 158672, 30041],
    },
    {
      name: "Volume Sales Y23",
      type: "column",
      data: [181667, 157660, 15742],
    },
  ];

  const firstGapNames = ["Al Arabi", "Shams", "Zaharati"];

  const secondGapData = [
    {
      name: "Base Market Share",
      type: "column",
      data: [4.96],
    },
    {
      name: "New Market Share",
      type: "column",
      data: [5.76],
    },
  ];

  const secondGapNames = ["Afia Corn 1.5L"];

  const volumeShares = [7.2, 6.9, 3.9, 10.7, 71.4];

  const volumeNames = ["Halah", "Hayat", "Nakheel", "Noor", "Other Brands"];

  return (
    <Box>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStartWrapper}
        onDragOver={handleDragOverWrapper}
        onDragEnd={handleDragEndWrapper}
      >
        {isAddSectionOpen && (
          <Box component={Paper} sx={ComponentBar}>
            <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <DroppableAddSection
                id="addSection"
                children={items.addSection}
              />
            </Box>
          </Box>
        )}
        <Grid container spacing={2}>
          
          <Grid item xs={12} md={12}>
            <DroppableGrid
              id="mainDash"
              children={items.mainDash}
              RemoveComponent={RemoveComponent}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box component={Paper} sx={BoxStyle}>
              <Grid container>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>
                      Underperforming Products
                    </Typography>
                    <Typography sx={TextStyle}>
                      AlArabi, Shams, and Zaharati all have a Growth Index below
                      1.00, indicating they are significantly underperforming
                      compared to the market benchmark. These products are not
                      meeting market expectations and may require strategic
                      adjustments
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <UnknownMixChart data={firstGapData} names={firstGapNames} />
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "columns",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>Opportunity</Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Revising pricing strategies to
                      enhance competitiveness.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Increasing marketing efforts to
                      boost visibility and sales.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon />
                      Evaluating product features or quality to better meet
                      customer needs.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box component={Paper} sx={BoxStyle}>
              <Grid container>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>
                      Products Below Market Benchmark but Above 1.00
                    </Typography>
                    <Typography sx={TextStyle}>
                      Afia and Sunny have a Growth Index greater than 1.00 but
                      are still below the benchmark set by the Oil Category
                      (Growth Index of 1.00). While these products are
                      performing better than the underperformers, there is room
                      for improvement to reach or exceed the market benchmark.
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <UnknownMixChart
                    data={secondGapData}
                    names={secondGapNames}
                    minValue={0}
                    maxValue={7}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "columns",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>Opportunity</Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Enhancing promotional activities to
                      improve market share, decrease the price of Afia Corn 1.5L
                      from 25.28 to 23.28.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Adjusting product positioning or
                      value propositions.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon />
                      Analyzing customer feedback and adjusting the product
                      offerings accordingly.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box component={Paper} sx={BoxStyle}>
              <Grid container>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>Top Performers</Typography>
                    <Typography sx={TextStyle}>
                      Halah, Hayat, Nakheel, and Noor have a Growth Index
                      greater than 1.00 and are performing well compared to the
                      market benchmark. These products are leveraging their
                      market presence effectively.
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <ThreePieChart
                    Sign={"%"}
                    Color={"#FF8F0D"}
                    Sales={volumeShares}
                    Names={volumeNames}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "columns",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <Typography sx={TitleStyle}>Opportunity</Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Continue investing in successful
                      marketing strategies.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon /> Explore opportunities to expand
                      their market reach or introduce complementary products.
                    </Typography>
                    <Typography sx={TextStyle}>
                      <TipsAndUpdatesIcon />
                      Maintain or improve pricing strategies to sustain their
                      competitive edge.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DndContext>
    </Box>
  );
}

export default GapsAndSolutions;
