import SearchIcon from "@mui/icons-material/Search";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";
import {theme} from "../../../Theme/index";

function SearchBar(){
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: "16px",
        backgroundColor: "#F9FAFB",
        display:"flex",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width:"600px",
        minWidth:"200px",
        // width: isMobile?"200px":isTablet?"250px":"400px",
      }));
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          width: '100%',
        },
      }));
return(
    <Search >
        <SearchIconWrapper>
        <SearchIcon color='primary'/>
        </SearchIconWrapper>
        <StyledInputBase 
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        />
    </Search>

          
)    

}
export default SearchBar;