import { Box, Typography } from "@mui/material";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from "../../Theme/index";
import Workbook from "../../Components/Tables/Workbook/Workbook";
import TwoPieChart from "../../Components/Charts/TwoPieChart/TwoPieChart";
import ThreePieChart from "../../Components/Charts/ThreePieChart/ThreePieChart";
import SalesColumnChart from "../../Components/Charts/SalesColumnChart/SalesColumnChart";
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import { getUrl } from "../../Utils/Common";
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader'
import axios from "axios";
import UnknownMixChart from "../../Components/Charts/UnknownMixChart/UnknownMixChart";

const CustomSpinner = () => (
  <div className="custom-spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className="spinner-svg"
    >
      <circle
        cx="25"
        cy="25"
        r="20"
        stroke="grey"
        strokeWidth="5"
        fill="none"
      />
    </svg>
  </div>
);


function SmartSimulator() {
    const [gridData, setGridData] = useState([]);
    const [salesNames, setSalesNames] = useState([]);
    const [salesValues, setSalesValues] = useState([]);
    const [sharesNames, setSharesNames] = useState([]);
    const [sharesValues, setSharesValues] = useState([]);
    const [loading, setLoading] = useState(true);  // State to indicate loading
    const [error, setError] = useState(null);  // State to handle any error
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const TitleStyle = {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize.medium,
        paddingY: 1,
        color: theme.palette.primary.main,
    };

    const ColumnBoxStyle = {
        display: "flex",
        gap: 1,
        marginBottom: 2,
        flexDirection: "column",
    };

    const fetchData = async () => {
        try {
          const response = await axios.get(`${getUrl()}/sim/get_project_by_name?`, {
            params: {
              project_name: "newsavola2",
            },
            headers: {
              'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
            },
          });
          setGridData(response.data.data)
          console.log(response.data)
          setSalesNames(response.data.new_stats["top_3_brands_sales_name"])
          setSalesValues(
            [{
            name: 'Base Value Sales',
            type: 'column',
            data: response.data.base_stats["top_3_brands_sales"]
          }, {
            name: 'New Value Sales',
            type: 'column',
            data: response.data.new_stats["top_3_brands_sales"]
          },]
          
        )
          setSharesNames(response.data.new_stats["top_3_brands_share_name"])
          setSharesValues(
            [{
              name: 'Base Value Sales',
              type: 'column',
              data: response.data.base_stats["top_3_brands_share"]
            }, {
              name: 'New Value Sales',
              type: 'column',
              data: response.data.new_stats["top_3_brands_share"]
            },]
          )
        } catch (error) {
          setError(error.message);  // Update state with error message
        } finally {
          setLoading(false);  // Set loading to false after fetch is complete
        }
      };
    const changeData = async (data) => {
      setLoading(true)
      console.log(data)
      try {
        const response = await axios.post(`${getUrl()}/sim/scenario_w_stats`,data, {
          headers: {
              'x-cors-api-key': "temp_7009e2de5e5acc5fbf86972fa24ba4c3"
          }});
        setGridData(response.data.data)
          console.log(response.data)
          setSalesNames(response.data.new_stats["top_3_brands_sales_name"])
          setSalesValues(
              [{
              name: 'Base Value Sales',
              type: 'column',
              data: response.data.base_stats["top_3_brands_sales"]
            }, {
              name: 'New Value Sales',
              type: 'column',
              data: response.data.new_stats["top_3_brands_sales"]
            },]
            
          )
          setSharesNames(response.data.new_stats["top_3_brands_share_name"])
          setSharesValues(
            [{
              name: 'Base Value Sales',
              type: 'column',
              data: response.data.base_stats["top_3_brands_share"]
            }, {
              name: 'New Value Sales',
              type: 'column',
              data: response.data.new_stats["top_3_brands_share"]
            },]
          )
      }
      catch(error){
        setError(error.message);
      }
      finally{
        setLoading(false);
      }
    }
      

    useEffect(()=>{
        fetchData();
    },[])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography sx={TitleStyle}>Work Book</Typography>
            <Grid container spacing={2}>

                <Grid item xs={9}>
                  <LoadingOverlay
                    active={loading}
                    text="Loading"
                    spinner={<BeatLoader color="grey" />}
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: 'rgba(236, 236, 236, 0.9)', // Background color
                        backdropFilter: 'blur(0.2px)',
                      }),
                      
                      content: (base) => ({
                        ...base,
                        color: 'grey', // Custom text color
                      }),
                    }}
                  >
                    <Workbook data={gridData} changeData={changeData} loading={loading}/>
                    </LoadingOverlay>
                </Grid>
                <Grid item xs={3} >
                <Grid container spacing={10}>  {/* Adjust the spacing value to control the gap */}
                        <Grid item xs={12}>
                        <LoadingOverlay
                            active={loading}
                            spinner={<BeatLoader color="grey" />}
                            text="Loading"
                            styles={{
                              overlay: (base) => ({
                                ...base,
                                background: 'rgba(236, 236, 236, 0.9)', // Background color
                                backdropFilter: 'blur(0.2px)',
                              }),
                              
                              content: (base) => ({
                                ...base,
                                color: 'grey', // Custom text color
                              }),
                            }}
                          >
                            <UnknownMixChart data={salesValues} names={salesNames}/>
                          </LoadingOverlay>
                        </Grid>
                        <Grid item xs={12}>
                        <LoadingOverlay
                              active={loading}
                              spinner={<BeatLoader color="grey" />}
                              text="Loading"
                              styles={{
                                overlay: (base) => ({
                                  ...base,
                                  background: 'rgba(236, 236, 236, 0.9)', // Background color
                                  backdropFilter: 'blur(0.2px)',
                                }),
                                
                                content: (base) => ({
                                  ...base,
                                  color: 'grey', // Custom text color
                                }),
                              }}
                            >
                            <UnknownMixChart data={sharesValues} names={sharesNames}/>
                          </LoadingOverlay>
                        </Grid>
                        {/*<Grid item xs={12} >
                          <SalesColumnChart />
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SmartSimulator;
