import React from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import MultiProgressBar from "../../ProgressBars/MultiProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";

function TopProd({sx}) {
    const BoxStyle = {
        display: "flex",
        flexDirection: "column",
        // width: 900,
        height: 270,
        ...sx,
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 15,
    }));

    const list = [
        {
            name: "Brand 1",
            brandValueIdx: 0.2,
            tradeVbrand: 1.7,
            rgmIdx: 0.2,
            alamieName: 3,
            lamarName: 1,
            alamie: 0.428571429,
            lamar: 0.142857143,
            p: 1,
            p12: 50,
        },
        {
            name: "Brand 2",
            brandValueIdx: 0.1,
            tradeVbrand: 1.6,
            rgmIdx: 0.9,
            alamieName: 2,
            lamarName: 1,
            alamie: 0.628571429,
            lamar: 0.242857143,
            p: 50,
            p12: 21,
        },
        {
            name: "Brand 3",
            brandValueIdx: 0.1,
            tradeVbrand: 9.6,
            rgmIdx: 0.1,
            alamieName: 1,
            lamarName: 3,
            alamie: 0.428571429,
            lamar: 0.542857143,
            p: 1,
            p12: 5,
        },
        {
            name: "Brand 4",
            brandValueIdx: 0.3,
            tradeVbrand: 86,
            rgmIdx: 0.3,
            alamieName: 4,
            lamarName: 1,
            alamie: 0.458571429,
            lamar: 0.144857143,
            p: 5,
            p12: 4,
        },
    ];
    function ColumnBox(){
        return(
            <Box>
            <Typography> Market</Typography>
            <Typography>Manufacturer</Typography>
            </Box>

        );
    }

    return (
        <>
            <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Top Products</Typography>
            <TableContainer sx={{ height: 200 }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Segments</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Volume Sales</StyledTableCell>
                            <StyledTableCell>Volume Sales IYA</StyledTableCell>
                            <StyledTableCell>Volume IYA</StyledTableCell>
                            <StyledTableCell>Avg Price/Volume</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((a, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell>Total</StyledTableCell>
                                <StyledTableCell>{ColumnBox()}</StyledTableCell>
                                <StyledTableCell>
                                <ProgressBar
                                        bgcolor={theme.palette.primary.light}
                                        progressChild={a.p * 10}
                                        number={a.p}
                                    />
                                    <ProgressBar
                                        bgcolor="#00E096"
                                        progressChild={a.p12 * 10}
                                        number={a.p12}
                                    />
                                    
                                </StyledTableCell>
                                <StyledTableCell>
                                <ColoredBox number={a.brandValueIdx} Color={theme.palette.primary.light} />
                                <ColoredBox number={a.brandValueIdx} Color={"#00E096"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                <ColoredBox number={a.brandValueIdx} Color={theme.palette.primary.light} />
                                <ColoredBox number={a.brandValueIdx} Color={"#00E096"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ColoredBox number={a.rgmIdx} Color={theme.palette.primary.light} />
                                    <ColoredBox number={a.brandValueIdx} Color={"#00E096"} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default TopProd;