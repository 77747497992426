import { Box } from "@mui/material";
function ProgressBar ({
    bgcolor,
    progressChild,
    number,
  })  {
    const Childdiv = {
      width: `${progressChild}%`,
      backgroundColor: bgcolor,
      borderRadius:"25px",
      minWidth:"50px",
    };
  
    const progresstext = {
        paddingLeft:"10px",
        textAlign:"left",
        color: "white"
    };
    const progressParent={
        display: "flex",
        height:"15px",
        borderRadius:"25px",     
        marginY:"5px",   
    }
  
    return (
      <Box sx={progressParent}>
          <div style={Childdiv}>
            <p style={progresstext}>{`${number}`}</p>
          </div>
      </Box>
    );
  };
export default ProgressBar;