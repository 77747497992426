
import React from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import MultiProgressBar from "../../ProgressBars/MultiProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";

function formatAsMillionDollars(amountInMillions) {
    // Use Intl.NumberFormat to format the number with no decimal places
    const millions = Math.round(amountInMillions / 1_000);
    const formattedAmount = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(millions);
    return `${formattedAmount}`;
}

function TopProducts({sx}) {
    const BoxStyle = {
        display: "flex",
        flexDirection: "column",
        // width: 700,
        height: 270,
        ...sx,
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 15,
    }));

    const list = [
        {
            name: "Oil Category",
            volumeSalesY22: 144474,
            volumeSalesY23: 187849,
            valueSalesY22: 1663268,
            valueSales23: 1932347,
            DYA: 0,
            IYA: 1,
            growthIDX: 1.16,
            fairValueSales: "",
            impactOnSales: "",
        },
        {
            name: "Afia",
            volumeSalesY22: 20550,
            volumeSalesY23: 24667,
            valueSalesY22: 302165,
            valueSales23: 323522,
            DYA: -1,
            IYA: 0.92,
            growthIDX: 1.07,
            fairValueSales: 351048,
            impactOnSales: -27526,
        },
        {
            name: "AlArabi",
            volumeSalesY22: 16356,
            volumeSalesY23: 16425,
            valueSalesY22: 186570,
            valueSales23: 181667,
            DYA: -2,
            IYA: 0.84,
            growthIDX: 0.97,
            fairValueSales: 216753,
            impactOnSales: -35086,
        },
        {
            name: "Dalal",
            volumeSalesY22: 9929,
            volumeSalesY23: 9662,
            valueSalesY22: 91155,
            valueSales23: 92372,
            DYA: 1,
            IYA: 0.87,
            growthIDX: 1.01,
            fairValueSales: 105902,
            impactOnSales: -13530,
        },
        {
            name: "Halah",
            volumeSalesY22: 6356,
            volumeSalesY23: 13443,
            valueSalesY22: 59411,
            valueSales23: 108310,
            DYA: 2,
            IYA: 1.57,
            growthIDX: 1.82,
            fairValueSales: 69022,
            impactOnSales: 39288,
        },
        {
            name: "Noor",
            volumeSalesY22: 14329,
            volumeSalesY23: 20109,
            valueSalesY22: 186980,
            valueSales23: 233911,
            DYA: 1,
            IYA: 1.08,
            growthIDX: 1.25,
            fairValueSales: 217229,
            impactOnSales: 16682,
        },
        {
            name: "Shams",
            volumeSalesY22: 18386,
            volumeSalesY23: 14160,
            valueSalesY22: 158672,
            valueSales23: 157660,
            DYA: -1,
            IYA: 0.86,
            growthIDX: 0.99,
            fairValueSales: 184342,
            impactOnSales: -26682,
        },
    ];

    return (
        <>
            <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Top Products</Typography>
            <TableContainer sx={{ height: 200 }}>
                <Table stickyHeader aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Volume Sales Y22</StyledTableCell>
                            <StyledTableCell>Volume Sales Y23</StyledTableCell>
                            <StyledTableCell>Value Sales Y22</StyledTableCell>
                            <StyledTableCell>Value Sales Y23</StyledTableCell>
                            <StyledTableCell>DYA</StyledTableCell>
                            <StyledTableCell>IYA</StyledTableCell>
                            <StyledTableCell>Growth IDX</StyledTableCell>
                            <StyledTableCell>Fair Value Sales</StyledTableCell>
                            <StyledTableCell>Impact on Sales</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((a, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell>{idx + 1}</StyledTableCell>
                                <StyledTableCell>{a.name}</StyledTableCell>
                                <StyledTableCell>
                                    {a.volumeSalesY22.toLocaleString()}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {a.volumeSalesY23.toLocaleString()}
                                </StyledTableCell>
                                <StyledTableCell>
                                   {`${a.valueSalesY22.toLocaleString()}$`}
                                </StyledTableCell>
                                <StyledTableCell>
                                   {`${a.valueSales23.toLocaleString()}$`}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ColoredBox number={a.DYA} Color={a.DYA>0? "#0BB783":a.DYA==0?"#0095FF":"#B22234"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ColoredBox number={a.IYA} Color={a.IYA>1? "#0BB783":a.IYA==1?"#0095FF":"#B22234"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ColoredBox number={a.growthIDX} Color={a.growthIDX>1? "#0BB783":a.growthIDX==1?"#0095FF":"#B22234"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {a.fairValueSales==''
                                    ?
                                    ''
                                    :
                                    `${a.fairValueSales.toLocaleString()}$`
                                    }
                                    
                                </StyledTableCell>
                                <StyledTableCell>
                                    {a.impactOnSales==''
                                    ?
                                    ''
                                    :
                                    <span style={{color:a.impactOnSales>0? "#0BB783":a.impactOnSales==0?"#0095FF":"#B22234"}}>
                                        {a.impactOnSales.toLocaleString()}$
                                    </span>
                                    }
                                    
                                </StyledTableCell>
                                
                               
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default TopProducts;