import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for WebKit browsers (Chrome, Safari)
  },
  '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
  'scrollbar-width': 'none', // Hide scrollbar for Firefox
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'auto',
  overflowY: 'auto',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  '&::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for WebKit browsers (Chrome, Safari)
  },
  '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
  'scrollbar-width': 'none', // Hide scrollbar for Firefox
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default Drawer;
