import React, { useEffect, useState } from "react";
import {
    Box,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { alpha } from "@mui/material";
import MultiProgressBar from "../../ProgressBars/MultiProgressBar";
import ProgressBar from "../../ProgressBars/ProgressBar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import InputColoredBox from "../../Boxes/TopProductBox/InputColoredBox";

function formatAsMillionDollars(amountInMillions) {
    // Use Intl.NumberFormat to format the number with no decimal places
    const millions = Math.round(amountInMillions / 1_000_000);
    const formattedAmount = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(millions);
    return `$${formattedAmount} K`;
}

function Workbook(props){
    const BoxStyle = {
        display: "flex",
        flexDirection: "column",
        // width: 900,
        height: 550,
    };

    const [newColor, setNewColor] = useState("#0095FF")
    

    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "USD"
      };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 15,
    }));

    useEffect(()=>{
        console.log(props.data)
    },
    [props])

    
    function ColumnBox(){
        return(
            <Box>
            <Typography>New</Typography>
            <Typography>Old</Typography>
            </Box>

        );
    }

    return (
        <>
            <TableContainer sx={{maxHeight: 550 }}>
                <Table stickyHeader aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>SKU</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>price</StyledTableCell>
                            <StyledTableCell>Size</StyledTableCell>
                            <StyledTableCell>WD</StyledTableCell>
                            <StyledTableCell>Volume Share</StyledTableCell>
                            <StyledTableCell>Value Share</StyledTableCell>
                            <StyledTableCell>Volume IDX</StyledTableCell>
                            <StyledTableCell>Value IDX</StyledTableCell>
                        </TableRow>
                        
                    </TableHead>
                    <TableBody>
                        {props.data.map((a, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell>{idx}</StyledTableCell>
                                <StyledTableCell>{a['SKU Name']}</StyledTableCell>
                                <StyledTableCell>{ColumnBox()}</StyledTableCell>
                                <StyledTableCell>
                                <InputColoredBox type={'Price'} name={a['SKU Name']} number={a.new_price.toFixed(2)} Color={a.new_price>a.base_price? "#0BB783":a.new_price===a.base_price?"#0095FF":"#B22234"} changeData={props.changeData} />
                                <ColoredBox number={a.base_price.toFixed(2)} Color={"#0095FF"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                <InputColoredBox type={'Size'} name={a['SKU Name']} number={a.new_size.toFixed(2)} Color={a.new_size>a.base_size? "#0BB783":a.new_size==a.base_size?"#0095FF":"#B22234"} changeData={props.changeData} />
                                <ColoredBox number={a.base_size.toFixed(2)} Color={"#0095FF"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                <InputColoredBox type={'WD'} name={a['SKU Name']} number={a.new_wd.toFixed(2)} Color={a.new_wd>a.base_wd? "#0BB783":a.new_wd==a.base_wd?"#0095FF":"#B22234"} changeData={props.changeData} />
                                <ColoredBox number={a.base_wd.toFixed(2)} Color={"#0095FF"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                <ProgressBar
                                        bgcolor= {a.new_market_share>a.base_market_share?alpha("#0BB783",0.6):a.new_market_share===a.base_market_share?alpha("#0095FF",0.6): alpha("#B22234",1.0)}
                                        progressChild={a.new_market_share * 100}
                                        number={(a.new_market_share).toFixed(2)}
                                    />
                                    <ProgressBar
                                        bgcolor={alpha("#0095FF" ,0.6)}
                                        progressChild={a.base_market_share * 100}
                                        number={(a.base_market_share).toFixed(2)}
                                    /> 
                                    
                                </StyledTableCell>
                                <StyledTableCell>
                                <ProgressBar
                                        bgcolor= {a.new_value_share>a.base_value_share?alpha("#0BB783",0.6):a.new_value_share===a.base_value_share?alpha("#0095FF",0.6): alpha("#B22234",1.0)}
                                        progressChild={a.new_value_share*1000}
                                        number={(a.new_value_share*100).toFixed(2)}
                                    />
                                    <ProgressBar
                                        bgcolor={alpha("#0095FF" ,0.6)}
                                        progressChild={a.base_value_share *100}
                                        number={(a.base_value_share*100).toFixed(2)}
                                    />
                                    
                                </StyledTableCell>
                                <StyledTableCell>
                                <ColoredBox number={(a.new_market_share/a.base_market_share).toFixed(2)} Color={(a.new_market_share/a.base_market_share)==1?"#0095FF": (a.new_market_share/a.base_market_share)<1?"#B22234":"#0BB783"} />
                                </StyledTableCell>
                                <StyledTableCell>
                                <ColoredBox number={(a.new_value_share/a.base_value_share).toFixed(2)} Color={(a.new_value_share/a.base_value_share)==1?"#0095FF": (a.new_value_share/a.base_value_share)<1?"#B22234":"#0BB783"} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default Workbook;
