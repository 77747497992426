import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { alpha, Input } from "@mui/material";
import {theme} from "../../../Theme/index";
import { useState } from "react";
function InputColoredBox({number, Color, changeData, type, name}){
    const [valueNmuber, setValueNumber] = useState(number)

const BoxStyle={
    
    border: `1px solid ${Color}`, // Adding border style
    fontWeight: "bold",
    color: Color,
    backgroundColor: alpha(Color,0.1),
    width: "50px",
    height: "24px",
    borderRadius:9,
    alignItems: "Center",
    justifyContent: "Center",
    textAlign: "center"
}

const handleInputChange = (e) => {
    const data = {
        "project_name": "newsavola2",
        "change_dict": {
        [name]: {
            [type]: parseFloat(valueNmuber)
            }
          },
        "new_products": null,
        "segmentation": null
    }
        changeData(data)
      };
const handleKey =(e) =>{
    if(e.key === 'Enter'){
        handleInputChange();
    }
    
}
    
return(
<Container>
    <Input style={BoxStyle} value={valueNmuber} onBlur={handleInputChange} onKeyDown={handleKey} onChange={(e)=>{setValueNumber(e.target.value)}} disableUnderline inputProps={{ style: {textAlign: 'center'} }}>
    </Input>
</Container>
)
}
export default InputColoredBox;