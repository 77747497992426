import React from "react";
import { AppBar, Toolbar, Box, Avatar } from "@mui/material";

const Navbar = () => {
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ borderBottom: "1px solid #e0e0e0" }}
    >
      <Toolbar sx={{ justifyContent: "space-between", paddingX: 3 }}>
        {/* Logo Section */}
        <Box display="flex" alignItems="center">
          <Avatar
            src="/Marketeers Logo Blue.png"
            alt="Marketeers Logo"
            sx={{ width: 300, height: "auto", marginRight: 2, padding: 2 }}
            variant="square"
          />

          {/* <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#002856" }}
            >
              Marketeers
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#737791", fontSize: "0.9rem" }}
            >
              Analytics • Insights •{" "}
              <Typography component="span" color="error" fontWeight="bold">
                Impact
              </Typography>
            </Typography>
          </Box> */}
        </Box>

        {/* Icons Section */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
