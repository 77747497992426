import React from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
function PriceElasticityTable(){
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontWeight:theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Afia Corn",
            down: 1.30,
            up: -0.79 ,
        },
        {
            name: "Afia Sunflower",
            down: 0.93,
            up: -0.86 ,
 
        },
    ];
    return(
        <>
        {/* <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Savola</Typography> */}
        <TableContainer sx={{ height:50 }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Price Elasticity</StyledTableCell>
                        <StyledTableCell>Down Pricing</StyledTableCell>
                        <StyledTableCell>Up Pricing</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.down}</StyledTableCell>
                            <StyledTableCell>{item.up}%</StyledTableCell>                         
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}
export default PriceElasticityTable;