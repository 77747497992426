import { Box, FormControl, Typography, MenuItem, Select } from "@mui/material";
import React from "react";

function InputGroupBox({ group, handleChange, groupOptions }) {
  return (
    <Box sx={{ mt: 2, ml: 3 }}>
      <Typography sx={{ fontWeight: "bold", p: 1, color: "#757575" }}>
        Group
      </Typography>
      <FormControl sx={{ minWidth: 100, ml: 1 }} size="small">
        <Select
          value={group}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {groupOptions.map((row) => (
            <MenuItem key={row.Brand} value={row.Brand}>
              {row.Brand}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InputGroupBox;