import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { Typography, alpha, Box, Button } from "@mui/material";
import { theme } from "../Theme/index";

function DraggableBox({ id, name, x, onClick }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useDraggable({ id });

  const style = {
    transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
    transition,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.18),

    // maxWidth:'200px',
    borderRadius: 25,
    border: "none",
    margin: "2px",
    height: "25px",
    textTransform: "none",
  };

  return (
    <Button
      onClick={onClick}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      sx={style}
    >
      {name}
    </Button>
  );
}

export default DraggableBox;
