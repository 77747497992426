import './App.css';
import { theme } from "../Theme/index";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../Components/Partials/Navbar/Navbar';
import Dashboard from '../Pages/Dashboard/Dashboard';
import MarketDynamics from '../Pages/MarketDynamics/MarketDynamics';
import SmartSimulator from '../Pages/SmartSimulator/SmartSimulator';
import GapsAndSolutions from '../Pages/GapsAndSolutions/GapsAndSolutions';
import PricingAndArchitecture from '../Pages/PricingAndArchitecture/PricingAndArchitecture';
import Temp from '../temporary/temp';
import TradeManagement from '../Pages/TradeManagement/TradeManagement';
import StatisticsTest from '../Pages/StatisticsTest/StatisticsTest';
import Login from "../Pages/Login/Login";
import RequireAuth from "../Utils/RequireAuth";
import UserProjectSelection from "../Pages/Login/UserProjectSelection";
// Redux imports
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setUserProject } from '../Redux/userPojectSlice/userProjectSlice';
import Scenarionew from 'src/Pages/ScenarioBuilder/Scenario';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Check local storage for user data
    const savedUserId = localStorage.getItem('userId');
    const savedProjectId = localStorage.getItem('projectId');
  
    console.log('Local storage values:', {
      savedUserId,
      savedProjectId,
    }); // Debugging
  
    if (savedUserId && savedProjectId) {
      // Dispatch user and project details to Redux
      dispatch(setUserProject({ userId: savedUserId, projectId: parseInt(savedProjectId, 10) }));
    } else {
      console.warn("User ID or Project ID missing in local storage.");
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {/* Public Route */}
          <Route path="/login" element={<Login />} />
          <Route path="/select-user-id" element={<UserProjectSelection />} />
          {/* Protected Routes */}
          <Route
            element={
              <RequireAuth>
                <Navbar />
              </RequireAuth>
            }
          >
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/market-dynamics" element={<MarketDynamics />} />
            <Route path="/smart-simulator" element={<SmartSimulator />} />
            <Route path="/gaps-solution" element={<GapsAndSolutions />} />
            <Route path="/pricing-architecture" element={<PricingAndArchitecture />} />
            <Route path="/promotion-visibility" element={<Temp />} />
            <Route path="/trade-management" element={<TradeManagement />} />
            <Route path="/statistics-test" element={<StatisticsTest />} />
            <Route path="/scenario-builder" element={<Scenarionew />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
