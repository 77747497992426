import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const store = configureStore({
  reducer: rootReducer, // equivalent to the rootReducer
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Add thunk if you need it
  devTools: process.env.REACT_APP_NODE_ENV !== "production", // Use REACT_APP_NODE_ENV for Webpack bundling
});

export default store;
