import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { renderToString } from "react-dom/server";
import FacebookCircularProgress from "../../shared/Loading/Loading";
function TwoColumnChartShareIdx({valueShare, currentvalueShare}) {
  const currentDateComparison = useSelector(
    (state) => state.dateCompare.currentDateComparison
  );
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  // State for the title and data
  const [title, setTitle] = useState("Value Share IDX");
  const [dataValues, setDataValues] = useState([]);
  const [categories, setCategories] = useState([
    "Category Growth",
    "AFIA Growth",
  ]);

  // Get project_id from Redux and sales_type
  const projectId = useSelector((state) => state.userProject.projectId);
  const [salesType, setSalesType] = useState("value_sales"); // Default sales type

  useEffect(() => {
    console.log(valueShare)
    setDataValues([valueShare, currentvalueShare])
  }, [projectId, salesType]); // Re-fetch when projectId or salesType changes

  useEffect(() => {
    console.log(valueShare)
  })

  const [options] = useState({
    chart: {
      type: "bar",
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val.toFixed(2)}%`; // Format labels as percentages
      },
      offsetY: 100,
      style: {
        fontSize: "11px",
        colors: ["#304758"],
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
        endingShape: "rounded",
        borderRadius: 6,
        distributed: true,
      },
    },
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: categories,
      labels: {
        show: false, // Remove category labels under the bars
        fontSize: "9px",
        fontWeight: 500,
      },
      tooltip: {
        enabled: true,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: minValue,
      max: maxValue,
      title: {
        text: "Growth (%)",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      offsetX: 5,
      offsetY: 5,
      markers: {
        radius: 25,
      },
    },
  });

  return (
    <>
      <Box
          sx={{
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
      {dataValues.length > 0 ? (
        <ReactApexChart
        options={options}
        series={dataValues}
        type="bar"
        height={200}
      />
      ) : (
        <FacebookCircularProgress />
      )}
      </Box>
    </>
  );
}

export default TwoColumnChartShareIdx;
