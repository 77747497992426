import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
import PriceElasticityTable from "../../Tables/DemoTables/PriceElasticityTable";
function PriceElasticity(){
   const series= [
    {
        name: "Afia Corn",
        data: [2.40, 2.20, 1.75, 1.0, 0.0, -0.10, -0.50, -0.60, -0.75,-0.83, -0.86, -0.87, -0.89, -0.90, -0.91]
    },
    {
        name: "Afia Sunflower",
        data: [2.55, 2.20, 1.0, 0.0, -0.25, -0.55, -0.75, -0.78, -0.80, -0.85, -0.86, -0.87, -0.89, -0.90, -0.90]
    }

    ]
    const options={
        chart: {
          
          type: 'line',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ['-50%', '-40%', '-30%', '-20%', '-10%', '0', '10%','20%','30%','40%','50%','60%','70%','80%','90%'],
        },
        yaxis: {
          min: -1.50,  // Minimum y-axis value
          max: 3.0,   // Maximum y-axis value
        //   stepSize:0.5,
        //   tickAmount:10,
          decimalsInFloat: 1,
        //   forceNiceScale: true, // Ensures the scale fits nicely around zero
          axisBorder: {
            show: true,
          },
        },

        stroke: {
          curve: 'straight',
        //   width: 2,
        },
        markers: {
          size: 0,
        },
        grid: {
          yaxis: {
            lines: {
              show: true, // Shows grid lines to make the zero line clear
            }
          },
          xaxis: {
            lines: {
              show: true, // Shows grid lines for the x-axis
            }
          },
          padding: {  top: 0, bottom: 0 },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#000',

            }
          ]
        },
        legend: {
          show: true,
          position: 'top',
        },
        responsive: [
          {
            breakpoint: 900, // Width in pixels
            options: {
              chart: {
                width: 510,
              },
              legend: {
                position: 'top',
              },
            },
          },
          {
            breakpoint: 3900, // Width in pixels
            options: {
              chart: {
                width: 540,
              },
              legend: {
                position: 'top',
              },
            },
          },
        ],

      };
      

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <PriceElasticityTable/>
        
            <ReactApexChart options={options} series={series} type="line" height={190}  />
        </Box>
      );
}
export default PriceElasticity;