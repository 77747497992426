// src/dragHandlers.js
import { arrayMove } from "@dnd-kit/sortable";
import { onDragEnd } from "./SortableHandlers";
import {
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
export function useCustomSensors() {
  return useSensors(
    useSensor(PointerSensor, {
      distance: 5,
      // Set activation constraints (e.g., delay, distance)
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
}

export function handleDragStart(event, setActiveId) {
  const { active } = event;
  const { id } = active;
  setActiveId(id);
}

export function handleDragOver(event, items, setItems, findContainer) {
  const { active, over, draggingRect } = event;
  const { id } = active;

  if (!over) return;

  const { id: overId } = over;
  const activeContainer = findContainer(id, items);
  const overContainer = overId;

  if (!activeContainer || !overContainer || activeContainer === overContainer) {
    return;
  }

  setItems((prev) => {
    const activeItems = prev[activeContainer];
    const overItems = prev[overContainer];

    const activeIndex = activeItems.findIndex((item) => item.id === id);
    const overIndex = overItems.findIndex((item) => item.id === overId);

    let newIndex;
    if (overId in prev) {
      // We're at the root droppable of a container
      newIndex = overItems.length + 1;
    } else {
      const isBelowLastItem =
        over &&
        overIndex === overItems.length - 1 &&
        draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    }

    return {
      ...prev,
      [activeContainer]: [
        ...prev[activeContainer].filter((item) => item.id !== active.id),
      ],
      [overContainer]: [
        ...prev[overContainer].slice(0, newIndex),
        items[activeContainer][activeIndex],
        ...prev[overContainer].slice(newIndex, prev[overContainer].length),
      ],
    };
  });
}
export function handleDragEnd(
  event,
  items,
  setItems,
  setActiveId,
  findContainer
) {
  const { active, over } = event;
  if (!over) return;

  const { id } = active;
  const { id: overId } = over;

  const activeContainer = findContainer(id, items);
  const overContainer = overId;

  if (!activeContainer || !overContainer || activeContainer !== overContainer) {
    return;
  }

  const activeIndex = items[activeContainer].findIndex(
    (item) => item.id === active.id
  );
  const overIndex = items[overContainer].findIndex(
    (item) => item.id === overId
  );

  if (activeIndex !== overIndex) {
    setItems((items) => ({
      ...items,
      [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    }));
  }

  setActiveId(null);
}

export function findContainer(id, items) {
  if (id in items) {
    return id;
  }

  return Object.keys(items).find((key) =>
    items[key].some((item) => item.id === id)
  );
}
