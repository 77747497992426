import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import { theme } from "../../Theme";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { SortableChart } from "../StatisticsTest/components/SortableChart";
import { DropDownDashboard } from "./DropDownDashboard";
import { onDragEndHandler } from "../../DragAndDrop/SortableHandlers";
import { useCustomSensors } from "../../DragAndDrop/DragHandlers";
import SalesColumnChart from "../../Components/Charts/SalesColumnChart/SalesColumnChart";
import TwoColumnChart from "../../Components/Charts/TwoColumnChart/TwoColumnChart";
import TopProd from "../../Components/Tables/Top2/TopProd";
import SourceShareTable from "../../Components/Tables/SourceShareTable/SourceShareTable";
import { data } from "./data";
import { getUrl } from "src/Utils/Common";

function Dashboard() {
  const currentChartType = useSelector((state) => state.chart.currentChartType);
  const currentDateComparison = useSelector(
    (state) => state.dateCompare.currentDateComparison
  );

  // Get projectId from Redux
  const projectId = useSelector((state) => state.userProject.projectId);

  const [quarters, setQuarters] = useState([]);
  const [monthsOption, setMonthsOption] = useState([]);
  const [showYears, setShowYears] = useState(false);
  const [users, setUsers] = useState(data);
  const [, setActiveId] = useState();

  function RemoveComponent(id) {
    let itemToMove;

    setUsers((prevUsers) => {
      itemToMove = prevUsers.find((item) => item.id === id);
      const updatedMainDash = prevUsers.filter((item) => item.id !== id);
      setItems((prevItems) => {
        const updatedAddSection = [...prevItems.addSection, itemToMove];

        return {
          ...prevItems,
          addSection: updatedAddSection,
        };
      });
      return updatedMainDash;
    });
  }

  const onDragStartHandler = (event) => {
    setActiveId(event.active.id);
  };

  const onDragCancelHandler = () => {
    setActiveId(null);
  };

  const [items, setItems] = useState({
    addSection: [
      {
        id: "SalesColumnChart",
        component: <SalesColumnChart />,
        name: "Sales",
        xs: 12,
        md: 4,
      },
      {
        id: "TwoColumnChart2",
        component: <TwoColumnChart Title={"Price IYA"} />,
        name: "Price Index Year Ago",
        xs: 12,
        md: 3,
      },
      {
        id: "TwoColumnChart3",
        component: <TwoColumnChart Title={"Price Idx"} />,
        name: "Price",
        xs: 12,
        md: 3,
      },
      {
        id: "TopProd2",
        component: <TopProd sx={{ flex: 1 }} />,
        name: "Top Products",
        xs: 12,
        md: 6,
      },
      {
        id: "SourceShare2",
        component: <SourceShareTable />,
        name: "Source Share",
        xs: 12,
        md: 6,
      },
    ],
  });
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);

  const sensors = useCustomSensors();

  useEffect(() => {
    // generate a random num between 1 and 100
    const x = Math.floor(Math.random() * 100);
    const y = Math.floor(Math.random() * 100);

    console.log(`Current date comparison: ${currentDateComparison}`);
    // Update the chart rendering based on the current date comparison
    // This could involve updating state or re-rendering the component

    // Update the users data with new values
    // Update the `dataValues` dynamically by modifying the `data` field
    setUsers((prevUsers) =>
      prevUsers.map((user) => ({
        ...user,
        component: React.cloneElement(user.component, {
          dataValues: user.component.props.dataValues?.map(
            (item) => {
              return { ...item, data: [x, y] };
            }
            // item.name === "Price IYA"
            // ?
            // Update the `data` field for matching items
            // : item
          ),
        }),
      }))
    );
  }, [currentDateComparison]);

  // Fetch options for years, quarters, and months using projectId
  useEffect(() => {
    async function fetchOptions() {
      if (!projectId) {
        console.warn("Project ID is not set in Redux state.");
        return;
      }

      try {
        const response = await fetch(
          `${getUrl()}/market_overview/year_quart_mon?project_id=${1}&area=SA TTL KSA`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.data;
        setShowYears(result.year_option || false);

        const validQuarters = Object.entries(result)
          .filter(([key, value]) => key.startsWith("Q") && value === true)
          .map(([key]) => key);
        setQuarters(validQuarters);

        setMonthsOption(result.months_option || []);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    }

    fetchOptions();
  }, [projectId]);

  // Map months to readable names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formattedMonths = monthsOption.map((month) => monthNames[month - 1]);

  const menuConfig = {
    years: showYears ? ["2022", "2023", "2024"] : null,
    months: formattedMonths.length > 0 ? formattedMonths : null,
    quarters: quarters.length > 0 ? quarters : null,
  };

  // Handle drag-and-drop
  const onDragEnd = (event) => {
    onDragEndHandler(event, setUsers, users);
  };

  const toggleAddSection = () => setIsAddSectionOpen((prev) => !prev);

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
  };

  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };
  return (
    <Box>
      {/* Add Section Toggle */}
      <AddSection sx={{ m: 2 }} toggleOpen={toggleAddSection} />
      {isAddSectionOpen && (
        <Box component={Paper} sx={ComponentBar}>
          <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <DroppableAddSection
              id="addSection"
              children={items.addSection}
              items={items.addSection}
              setUsers={setUsers}
              setItems={setItems}
            />
          </Box>
        </Box>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {/* Dropdown for filtering */}
        <DropDownDashboard menuConfig={menuConfig} />
      </div>
      {/* Dashboard */}
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        sensors={sensors}
        onDragStart={onDragStartHandler}
        onDragCancel={onDragCancelHandler}
      >
        <SortableContext items={users} strategy={rectSortingStrategy}>
          <Typography sx={TitleStyle}>Market Brands Overview</Typography>
          <Grid container spacing={2}>
            {users.map((user) => (
              <Grid item xs={user.xs} sm={6} md={user.md} key={user.id}>
                <SortableChart
                  user={user}
                  RemoveComponent={() => RemoveComponent(user.id)}
                />
              </Grid>
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </Box>
  );
}

export default Dashboard;
