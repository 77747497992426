import { Paper, Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import ReactApexChart from "react-apexcharts";
import { theme } from "../../../Theme";
import { alpha } from "@mui/material";
import InfoBar from "../../Partials/Infobar/InfoBar";

function LineChart({ Title }) {
  const data = [
    {
      name: "Market",
      data: [30, 66, 95, 100, 109, 62, 77, 81, 100],
    },
    {
      name: "Brand 1",
      data: [19, 60, 45, 71, 75, 40, 40, 40, 80],
    },
    {
      name: "Brand 2",
      data: [11, 6, 50, 29, 34, 22, 37, 41, 20],
    },
  ];

  const BoxStyle = {
    display: "flex",
    flexDirection: "column",
    // width:500,
    // height:250,
  };
  // function customFormatter(seriesName, opts){
  //     const dataPointIndex = opts.seriesIndex;
  //     const value = opts.w.globals.series[dataPointIndex];
  //     const total = opts.w.config.series.reduce((acc, curr) => acc + curr, 0);
  //     const percentage = ((value / total) * 100).toFixed(1); // Calculate percentage

  //     return `${seriesName}: ${percentage}${Sign}`;
  // }
  const [options, setOptions] = useState({
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 900, // Width in pixels
        options: {
          chart: {
            width: 510,
          },
          legend: {
            position: "top",
          },
        },
      },
      {
        breakpoint: 3900, // Width in pixels
        options: {
          chart: {
            width: 540,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
    chart: {
      type: "line",
      // height: 350
      toolbar: {
        show: false,
      },
    },
    grid: {
      row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0.5
      },
      show: true,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },

    labels: ["Market", "Brand 1", "Brand 2"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    fill: {
      opacity: 1,
    },

    legend: {
      position: "bottom",
      offsetX: 5,
      offsetY: 5,
      markers: {
        radius: 25,
      },
      //   formatter: customFormatter,
    },
  });

  return (
    <>
      <Typography fontWeight={theme.typography.fontWeightBold} px={1}>
        {Title}
      </Typography>
      <ReactApexChart
        options={options}
        series={data}
        type="line"
        height={180}
      />
    </>
  );
}
export default LineChart;
