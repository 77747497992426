import React from "react";
import { useDroppable } from "@dnd-kit/core";
import DraggableBox from "./DraggableBox";
import { Typography, Box } from "@mui/material";
import { theme } from "../Theme";

function DroppableAddSection({ id, children, items, setUsers, setItems }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  const style = {
    display: "flex",
    flesWrap: "wrap",
    padding: 1,
    margin: 1,
    flex: 1,
  };
  const textStyle = {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    p: 0,
  };
  const handleBoxClick = (id) => {
    // Find the item by its ID in the `items` list
    const updatedItem = items.find((item) => item.id === id);

    // Check if the item is already in the `users` list
    setUsers((prevUsers) => {
      const isItemInUsers = prevUsers.some((user) => user.id === id);

      // Only add the item if it's not already in the `users` array
      if (!isItemInUsers) {
        return [...prevUsers, updatedItem]; // Add the item to the users array
      }

      // If the item already exists, return the previous state unchanged
      return prevUsers;
    });
    setItems((prevItems) => {
      const updatedAddSection = prevItems.addSection.filter(
        (item) => item.id !== id
      );
      return {
        ...prevItems,
        addSection: updatedAddSection,
      };
    });
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children.length > 0 ? (
        children.map((child) => (
          <DraggableBox
            key={child.id}
            id={child.id}
            name={child.name}
            onClick={() => handleBoxClick(child.id)}
          />
        ))
      ) : (
        <Box>
          <Typography sx={textStyle}>Drag Items Here</Typography>
        </Box>
      )}
    </div>
  );
}

export default DroppableAddSection;
