import { combineReducers } from "redux";
import userProjectReducer from "../userPojectSlice/userProjectSlice";
import chartReducer from "../chartSlice/chartSlice"
import dateCompareReducer from "../dateCompareSlice/dateCompareSlice";

const rootReducer = combineReducers({
    userProject: userProjectReducer,
    chart: chartReducer,
    dateCompare: dateCompareReducer,


    // Add other reducers here if needed
  });


  export default rootReducer;
  

  