import { Box, Typography, Paper, useMediaQuery } from "@mui/material";
import React, { useState } from 'react';
import AddSection from "../Components/Partials/AddSection/AddSection";
import { theme } from "../Theme";
import { DndContext,closestCorners, } from '@dnd-kit/core';
import TopProducts from "../Components/Tables/TopProductsTable/TopProducts";
import { arrayMove } from '@dnd-kit/sortable';
import BrandSales from "../Components/Charts/ActualVsExpectedMixChart/BrandSales";

import SourceShareTable from "../Components/Tables/SourceShareTable/SourceShareTable";
import TopProd from "../Components/Tables/Top2/TopProd";
import SalesColumnChart from "../Components/Charts/SalesColumnChart/SalesColumnChart";
import TwoColumnChart from "../Components/Charts/TwoColumnChart/TwoColumnChart";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DroppableAddSection from "../DragAndDrop/DroppableAddSection";
import DroppableGrid from "../DragAndDrop/DroppableGrid";
import { handleDragStart, handleDragOver, handleDragEnd, findContainer, useCustomSensors } from '../DragAndDrop/DragHandlers';


function Dashboard () {
  function RemoveComponent(id) {
    setItems((prevItems) => {
      const itemToMove = prevItems.mainDash.find(item => item.id === id);
      const updatedMainDash = prevItems.mainDash.filter(item => item.id !== id);
      const updatedAddSection = [...prevItems.addSection, itemToMove];
      
      return {
        ...prevItems,
        mainDash: updatedMainDash,
        addSection: updatedAddSection,
      };
    });
  };
  const [items, setItems] = useState({
    addSection: [
      { id: 'SalesColumnChart', component: <SalesColumnChart  />, name: "Sales", xs: 12, md:4 },
      { id: 'TwoColumnChart2', component: <TwoColumnChart  Title={"Price IYA"} />, name: "Price Index Year Ago", xs: 12 , md:3},
      { id: 'TwoColumnChart3', component: <TwoColumnChart   Title={"Price Idx"} />, name: "Price", xs: 12, md:3 },
      { id: 'TopProd2', component:  <TopProd  sx={{flex:1}}/>, name:"Top Products", xs:12, md:6 },
      { id: 'SourceShare2', component: <SourceShareTable />, name:"Source Share", xs:12, md:6  }, 

    ],
    mainDash: [

      { id: 'TopProducts', component: <TopProducts  />, name: "Top Products", xs: 12 ,md: 8},
      { id: 'BrandSales', component: <BrandSales  />, name:"Brand Sales", xs:12 ,md:6},
      { id: 'TopProd', component:  <TopProd  sx={{flex:1}}/>, name:"Top Products", xs:12, md:6 },
      { id: 'SourceShare', component: <SourceShareTable />, name:"Source Share", xs:12, md:6 },
      { id: 'TwoColumnChart1', component: <TwoColumnChart  Title={"Price "} />, name: "Price Index", xs: 12, md:3 },
    ]
  });
  const [activeId, setActiveId] = useState();
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
  const sensors = useCustomSensors();

const toggleAddSection = () => {
    setIsAddSectionOpen(!isAddSectionOpen);
  };
  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      }));
    }

    setActiveId(null);
  }

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
   };
  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '10px',
    padding: 1,
    display: "flex", 
    flexDirection: "column",
    position: "relative",
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };

  return (
    <Box>
      <AddSection toggleOpen={toggleAddSection} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        {isAddSectionOpen && (
          <Box component={Paper} sx={ComponentBar}>
            <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <DroppableAddSection id="addSection" children={items.addSection} />
            </Box>
          </Box>
        )}

        <Typography sx={TitleStyle}>Market Brands Overview</Typography>

        <DroppableGrid id="mainDash" children={items.mainDash} RemoveComponent={RemoveComponent} />
        {/* <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay> */}
      </DndContext>
    </Box>
  );
};

export default Dashboard;
