import TotalCategory from "../../Components/Tables/DemoTables/TotalCategory";
import Savola from "../../Components/Tables/DemoTables/Savola";
import SFBrandInteraction from "../../Components/Tables/DemoTables/SFBrandInteraction";
import CornBrandInteraction from "../../Components/Tables/DemoTables/CornBrandInteraction";
import PricingStrategy from "../../Components/Tables/DemoTables/PricingStrategy";
import PriceElasticity from "../../Components/Charts/DemoCharts/PriceElasticity";

export const data =  [
    {
      id: "TotalCategory",
      component: <TotalCategory />,
      name: "Total Category",
      xs: 12,
      md: 6,
    },
    { id: "Savola", component: <Savola />, name: "Savola", xs: 12, md: 6 },
    {
      id: "SunflowerBrandInteraction",
      component: <SFBrandInteraction />,
      name: "Sunflower Brand Interaction",
      xs: 12,
      md: 6,
    },
    {
      id: "CornBrandInteraction",
      component: <CornBrandInteraction />,
      name: "Corn Brand Interaction",
      xs: 12,
      md: 6,
    },
    {
      id: "PricingStrategy",
      component: <PricingStrategy />,
      name: "Pricing Strategy",
      xs: 12,
      md: 8,
    },
    {
      id: "PriceElasticity",
      component: <PriceElasticity />,
      name: "Price Elasticity",
      xs: 12,
      md: 6,
    },
  ]