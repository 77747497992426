import React from "react";
import Users from "./SortableCharts";

const StatisticsTest = () => {
  return (
    <>
      <Users />
    </>
  );
};
export default StatisticsTest;
