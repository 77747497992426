import {
    Alert,
    AlertTitle,
    Box,
    Paper,
    Stack,
    Grid,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import ReactApexChart from "react-apexcharts";
  import { StyledTitleTypography } from "../Styled/Typography";
  import { getElasticity } from "./Network/Lib/Simulator";
  import { Elasticity } from "./Table";
  
  export function ChartValueSalesandVolumeSales({
    valueSales,
    volumeSales,
    labels,
  }) {
    const [options, setOptions] = useState({
      colors: ["#00265E", "#A40406"],
  
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        height: 350,
        type: "line",
      },
  
      stroke: {
        width: [0, 2],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: labels,
  
      yaxis: [
        {
          title: {
            text: "Value Sales (M SAR)",
          },
          min: 0,
          max: 350,
          tickAmount: 4,
        },
        {
          opposite: true,
          title: {
            text: "Volume Sales (M Kg)",
          },
        },
      ],
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: "Value Sales",
        type: "column",
        data: valueSales, //your data goes here
      },
      {
        name: "Market Volume",
        type: "line",
        data: volumeSales, //your data goes here
      },
    ]);
  
    return (
      <Box sx={{ p: 2 }}>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </Box>
    );
  }
  
  export const ChartPriceIndex = () => {
    // const names = ["AL ARABI", " AFIA", " AL TAYEB", " LIZA", "SLITE"];
    // const years=[2019, 2020, 2021, 2022]
    const [options, setObject] = useState({
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2, 3, 4, 5],
      },
  
      stroke: {
        curve: "straight",
      },
  
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [2019, 2020, 2021, 2022],
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: "AL ARABI",
        data: [100, 111, 113, 121],
      },
      {
        name: "AFIA",
        data: [100, 108, 109, 113],
      },
      {
        name: "AL TAYEB",
        data: [100, 106, 108, 113],
      },
  
      {
        name: "LIZA",
        data: [100, 100, 100, 104],
      },
  
      {
        name: "SLITE",
        data: [100, 101, 97, 101],
      },
    ]);
  
    return (
      <Paper sx={{ p: 2, mt: 3 }}>
        <StyledTitleTypography>Price Index</StyledTitleTypography>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={400}
        />
      </Paper>
    );
  };
  
  export const ChartGRMIndex = ({
    titleY,
    titleX,
    titleLine,
    dataX,
    dataY,
    line,
    labels,
  }) => {
    const [options, setOptions] = useState({
      colors: ["#A40406", "#00265E", "#FEB019"],
  
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        height: 350,
        type: "line",
        stacked: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
      },
      stroke: {
        width: [1, 1, 2],
        colors: ["#A40406", "#00265E", "#FEB019"],
      },
      fill: {
        colors: ["#A40406", "#00265E"],
      },
      xaxis: {
        categories: labels,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#00265E",
          },
          labels: {
            style: {
              colors: "#00265E",
            },
          },
          title: {
            text: titleY,
            style: {
              color: "#00265E",
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: titleY,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
            color: "green",
          },
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        {
          seriesName: titleLine,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#FEB019",
          },
          labels: {
            style: {
              colors: "#FEB019",
            },
          },
          title: {
            text: titleLine,
            style: {
              color: "#FEB019",
            },
          },
        },
      ],
  
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
        colors: ["#A40406", "#00265E", "#FEB019"],
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: titleX,
        type: "column",
        data: dataX,
      },
      {
        name: titleY,
        type: "column",
        data: dataY,
      },
      {
        name: titleLine,
        type: "line",
        data: line,
      },
    ]);
  
    return (
      <Paper sx={{ p: 3 }}>
        <StyledTitleTypography>Brand Value</StyledTitleTypography>
        <Box sx={{ border: "3px solid #F2F4F7", borderRadius: "10px" }}>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        </Box>
      </Paper>
    );
  };
  
  export const ChartbrandValue = () => {
    const [options, setOptions] = useState({
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#B0DCFF",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.7,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
  
      grid: {
        borderColor: "#e7e7e7",
        // row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0.5
        // },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ["AFIA", "DALAL", "HATAT", "PURE"],
        title: {
          // text: 'Month'
        },
      },
      yaxis: {
        title: {
          // text: 'Temperature'
        },
        // min: 0,
        // max: 12
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: "High - 2013",
        data: [1.6, 0.7, 0, 0.6],
      },
    ]);
  
    return (
      <>
        <StyledTitleTypography>Brand Value</StyledTitleTypography>
  
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
        <Stack direction="row" sx={{ gap: 2 }}>
          <Alert severity="error">
            <ul>
              <li>
                {" "}
                <strong>AFIA:</strong> Invest in <strong> Brand</strong>
              </li>
              <li>
                {" "}
                <strong>DALAL:</strong> Invest in <strong> Trade</strong>
              </li>
  
              <li>
                {" "}
                <strong>PURE:</strong> Invest in <strong> Trade</strong>
              </li>
            </ul>
          </Alert>
          <Alert severity="success" sx={{ alignItems: "center" }}>
            <strong>AFIA:</strong> Keep Trade Push.
          </Alert>
        </Stack>
      </>
    );
  };
  
  export const ChartPriceValue = () => {
    const [options, setObject] = useState({
      chart: {
        type: "line",
  
        height: 350,
  
        zoom: {
          enabled: true,
  
          type: "xy",
        },
  
        toolbar: {
          show: true,
  
          tools: {
            download: true,
  
            selection: false,
  
            zoom: false,
  
            zoomin: false,
  
            zoomout: false,
  
            pan: false,
  
            reset: false,
          },
        },
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: "SHAMS",
        data: [{ x: 7, y: 8 }],
      },
  
      {
        name: "DALAL",
        data: [{ x: 8, y: 1 }],
      },
      {
        name: "HAYAT",
        data: [{ x: 19, y: 14 }],
      },
      {
        name: "PURE",
        data: [{ x: 32, y: 20 }],
      },
      {
        name: "AL AMIR",
        data: [{ x: 42, y: 22 }],
      },
      {
        name: "MAHA",
        data: [{ x: 51, y: 23 }],
      },
      {
        name: "line",
        data: [
          {
            x: 0,
  
            y: 0,
          },
          {
            x: 55,
  
            y: 32,
          },
        ],
      },
    ]);
  
    return (
      <>
        <StyledTitleTypography>Price Value</StyledTitleTypography>
  
        <ReactApexChart options={options} series={series} height={350} />
  
        <Stack direction="row" sx={{ gap: 2 }}>
          <Alert severity="error">
            <ul>
              <li>
                {" "}
                <strong>SHAMS:</strong> is<strong> Weaker Value</strong>
              </li>
  
              <li>
                {" "}
                <strong>HAYAT:</strong> is<strong> Weaker Value</strong>
              </li>
              <li>
                {" "}
                <strong>PURE:</strong> is<strong> Weaker Value</strong>
              </li>
            </ul>
          </Alert>
  
          <Alert severity="success" sx={{ alignItems: "center" }}>
            <ul>
              <li>
                {" "}
                <strong>ALAMIR:</strong> is<strong> Stronger Value</strong>
              </li>
              <li>
                <strong>MAHA:</strong> is<strong> Stronger Value</strong>
              </li>
            </ul>
          </Alert>
        </Stack>
      </>
    );
  };
  
  export const PriceElasticity = (props) => {
    const [rowData, setRowData] = useState([]);
    const [options, setOptions] = useState({
      chart: {
        height: 450,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 1,
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories: [
          -110, -100, -90, -80, -70, -60, -50, -40, -30, -20, -10, 0, 10, 20, 30,
          40, 50, 60, 70, 80, 90, 100, 110,
        ],
        title: {
          text: "Price (SAR)",
        },
      },
      yaxis: {
        title: {
          text: "Change in SKU Volume Share ",
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        floating: true,
      },
    });
    const [series, setSeries] = useState([]);
  
    useEffect(() => {
      getElasticity(props).then((response) => {
        const skus = [];
        for (const i in Object.keys(response.data)) {
          if (i > 1) {
            const data = {};
            for (const x in response.data[Object.keys(response.data)[i]]) {
              if (
                typeof response.data[Object.keys(response.data)[i]][x] ===
                "string"
              ) {
                response.data[Object.keys(response.data)[i]][x] = null;
              }
            }
            data["name"] = Object.keys(response.data)[i];
            data["data"] = response.data[Object.keys(response.data)[i]];
            skus.push(data);
          }
        }
        const rowdata = [];
        for (const i in Object.keys(response.data.slopes)) {
          rowdata.push({
            name: Object.keys(response.data.slopes)[i],
            slope: response.data.slopes[Object.keys(response.data.slopes)[i]],
          });
        }
        setRowData(rowdata);
        setOptions({
          chart: {
            height: 400,
            type: "line",
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 4,
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: response.data.Percentage,
            title: {
              text: "Price (SAR)",
            },
          },
          yaxis: {
            title: {
              text: "Change in SKU Volume Share",
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "left",
            floating: true,
          },
        });
        setSeries(skus);
      });
    }, []);
  
    return (
      <Box>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={8}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={360}
            />
          </Grid>
          <Grid item xs={4}>
            <Elasticity key={rowData} data={rowData} />
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  export const AssortmentChart = ({ WD }) => {
    const [options, setOptions] = useState({
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        height: 50,
        type: "line",
        stacked: false,
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        `AL ARABI PURE VEGETA.OIL 1.5LX2 PET/H`,
        "AL ARABI PURE VEGETABLE OIL 1.5L PET",
        "AL ARABI PURE VEGETABLE OIL 2.9L PET",
        "AL ARABI VEG OIL 10L TIN",
        "AL ARABI SUNFLOWER&PALM OLEIN 1.8L TIN",
        "AL ARABI VEG OIL 17L TIN",
        "AL ARABI PURE VEGETA.OIL 1.5LX3 PET/H",
      ],
      markers: {
        size: 5,
      },
      xaxis: {
        type: "string",
      },
      yaxis: {
        title: {
          text: "Weight Distribution",
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
      },
      legend: {
        position: "top",
      },
    });
  
    const [series, setSeries] = useState([
      {
        name: "Skus",
        type: "column",
        data: [27, 49, 63, 70, 74, 78, 81],
      },
      {
        name: "Weight Distribution",
        type: "area",
        data: [0.0853, 0.1481, 0.1086, 0.0793, 0.0188, 0.0206, 0.0212].map((e) =>
          Math.floor(e * 100)
        ),
      },
      {
        name: "Sales Rate",
        type: "line",
        data: [
          0.349589683, 0.16110736, 0.148710866, 0.095208071, 0.244680851,
          0.183495146, 0.17688679,
        ].map((e) => Math.floor(e * 100)),
      },
    ]);
  
    return (
      <Paper sx={{ p: 3, mt: 2 }}>
        <StyledTitleTypography>Assortment</StyledTitleTypography>
        <Box
          sx={{
            fontSize: "1.1rem",
  
            pb: 3,
            color: "#6D6D6D",
          }}
        >
          Optimize product selection by considering financial goals, local market
          needs, and seasonal demand to drive full efficiency.
        </Box>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={500}
        />
      </Paper>
    );
  };
  
  export const InterSizeChart = () => {
    const [options, setOptions] = useState({
      chart: {
        height: 350,
        type: "rangeArea",
        toolbar: {
          show: true,
  
          tools: {
            download: true,
  
            selection: false,
  
            zoom: false,
  
            zoomin: false,
  
            zoomout: false,
  
            pan: false,
  
            reset: false,
          },
        },
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
      },
  
      stroke: {
        curve: "straight",
        width: [0, 0, 2, 2],
      },
  
      markers: {
        hover: {
          sizeOffset: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val;
          },
        },
      },
      annotations: {
        xaxis: [
          {
            x: "Sep",
            borderColor: "gray",
            label: {
              orientation: "horizontal",
  
              style: {
                color: "#fff",
                background: "#04265C",
              },
              text: "-14 %",
            },
          },
          {
            x: "Oct",
            borderColor: "gray",
            label: {
              orientation: "horizontal",
  
              style: {
                color: "#fff",
                background: "#04265C",
              },
              text: "-17 %",
            },
          },
          {
            x: "Nov",
            borderColor: "gray",
            label: {
              orientation: "horizontal",
              style: {
                color: "#fff",
                background: "#04265C",
              },
              text: "-31 %",
            },
          },
        ],
      },
    });
  
    const [series, setSeries] = useState([
      {
        data: [
          {
            x: "0.200",
            y: [14, 22],
          },
          {
            x: "0.400",
            y: [16, 24],
          },
          {
            x: "0.500",
            y: [18, 26],
          },
        ],
      },
    ]);
  
    return (
      <Paper sx={{ p: 3, mt: 2 }}>
        <StyledTitleTypography>Inter-size discount</StyledTitleTypography>
  
        <ReactApexChart
          options={options}
          series={series}
          type="rangeArea"
          height={300}
        />
      </Paper>
    );
  };
  
  export function ChartDountMarketShare({ marketShare, currentmarketShare }) {
    const [options, setOptions] = useState({
        labels: ["New", "Current"],
        colors: [
          `${marketShare > currentmarketShare ? "#41DD64" : "#D41414"}`,
          "#4F73D5",
        ],
        legend: {
          show: true,
    
          offsetX: 20,
          offsetY: 0,
        },
        plotOptions: {
          radialBar: {
            size: undefined,
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            offsetX: -10,
            offsetY: 0,
            hollow: {
              margin: 1,
              size: "50%",
    
              imageClipped: true,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
    
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: undefined,
                color: undefined,
                offsetY: 0,
              },
              value: {
                show: true,
                fontFamily: undefined,
                color: undefined,
                offsetY: 0,
                color: "#4F73D5",
                fontWeight: "bold",
                formatter: function (val) {
                  return val + "%";
                },
              },
              total: {
                show: true,
                label: `${marketShare + "%"}`,
                color: `${marketShare > currentmarketShare ? "#41DD64" : "#D41414"}`,
              },
            },
          },
        },
        series: [marketShare, currentmarketShare],
      });
    console.log(currentmarketShare);
    console.log(marketShare);
    const [series, setSeries] = useState([
      parseFloat(marketShare).toFixed(2),
      parseFloat(currentmarketShare).toFixed(2),
    ]);
    return (
      <>
        <Box
          sx={{
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ReactApexChart options={options} series={series} type="radialBar" />
          <Box sx={{ marginRight: "15px" }}>
            <Typography sx={{ fontWeight: "bold", color: "#8E9297" }}>
              Market Share
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                pr: 1,
                fontSize: "1.2rem",
                overflowWrap: "anywhere",
                width: "80px",
              }}
            >
              {marketShare}%
            </Typography>
          </Box>
        </Box>
      </>
    );
  }
  
  export function ChartDountValueShare({ valueShare, currentvalueShare }) {
    const [options, setOptions] = useState({
      labels: ["New", "Current"],
      colors: [
        `${valueShare > currentvalueShare ? "#41DD64" : "#D41414"}`,
        "#4F73D5",
      ],
      legend: {
        show: true,
  
        offsetX: 20,
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          size: undefined,
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: -10,
          offsetY: 0,
          hollow: {
            margin: 1,
            size: "50%",
  
            imageClipped: true,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
          },
  
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: undefined,
              color: undefined,
              offsetY: 0,
            },
            value: {
              show: true,
              fontFamily: undefined,
              color: undefined,
              offsetY: 0,
              color: "#4F73D5",
              fontWeight: "bold",
              formatter: function (val) {
                console.log(val)
                return val + "%";
              },
            },
            total: {
              show: true,
              label: `${valueShare + "%"}`,
              color: `${valueShare > currentvalueShare ? "#41DD64" : "#D41414"}`,
            },
          },
        },
      },
      series: [valueShare, currentvalueShare],
    });
  
    const [series, setSeries] = useState([
      parseFloat(valueShare),
      parseFloat(currentvalueShare),
    ]);
    return (
      <>
        <Box
          sx={{
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ReactApexChart options={options} series={series} type="radialBar" />
          <Box sx={{ marginRight: "15px" }}>
            <Typography sx={{ fontWeight: "bold", color: "#8E9297" }}>
              Value Share
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                pr: 1,
                fontSize: "1.2rem",
                overflowWrap: "anywhere",
                width: "80px",
              }}
            >
              {valueShare} %
            </Typography>
          </Box>
        </Box>
      </>
    );
  }
  
  export function ChartDountRevenue({
    revenue,
    currentrevenue,
    revenueperc,
    currentrevenueperc,
  }) {
    const [options, setOptions] = useState({
      labels: ["New", "Current"],
      colors: [
        `${revenueperc > currentrevenueperc ? "#41DD64" : "#D41414"}`,
        "#4F73D5",
      ],
      legend: {
        show: true,
  
        offsetX: 20,
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          size: undefined,
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: -10,
          offsetY: 0,
          hollow: {
            margin: 1,
            size: "50%",
  
            imageClipped: true,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
          },
  
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: undefined,
              color: undefined,
              offsetY: 0,
            },
            value: {
              show: true,
              label: `${Math.floor(currentrevenueperc).toFixed(2)}%`,
              offsetY: 0,
              color: "#4F73D5",
              fontWeight: "bold",
            },
            total: {
              show: true,
              label: `${Math.floor(revenueperc)}%`,
              color: `${
                revenueperc > currentrevenueperc ? "#41DD64" : "#D41414"
              }`,
            },
          },
        },
      },
      series: [revenueperc, currentrevenueperc],
    });
  
    const [series, setSeries] = useState([
      revenueperc,
      currentrevenueperc,
    ]);
    return (
      <>
        <Box
          sx={{
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <ReactApexChart options={options} series={series} type="radialBar" />
          <Box sx={{ marginRight: "15px" }}>
            <Typography sx={{ fontWeight: "bold", color: "#8E9297" }}>
              Revenue(SAR)
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                pr: 1,
                fontSize: "1.2rem",
                overflowWrap: "anywhere",
                width: "80px",
              }}
            >
              {(revenue / 1000000).toFixed(3)}
              <Typography
                component="span"
                sx={{ fontWeight: "bold", color: "#8E9297" }}
              >
                M
              </Typography>
            </Typography>
          </Box>
        </Box>
      </>
    );
  }