import React from "react";
import { Box, CircularProgress } from "@mui/material";

function FacebookCircularProgress(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Background Circular Progress */}
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[300], // Adjust background color
        }}
        size={40} // You can adjust the size
        thickness={3} // Adjust thickness
        value={100} // Full circle for background
        {...props}
      />
      {/* Foreground Circular Progress */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#1a90ff", // Adjust foreground color
          animationDuration: "550ms", // Smooth animation
          position: "absolute",
        }}
        size={40} // Match size with background
        thickness={3} // Match thickness with background
        {...props}
      />
    </Box>
  );
}

export default FacebookCircularProgress;
