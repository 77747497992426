import { Button, Box } from "@mui/material";
import { alpha } from "@mui/system"; // Make sure alpha is correctly imported
import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { theme } from "../../../Theme/index";
import TopProducts from "../../Tables/TopProductsTable/TopProducts";
import SalesColumnChart from "../../Charts/SalesColumnChart/SalesColumnChart";
import TwoColumnChart from "../../Charts/TwoColumnChart/TwoColumnChart";
import PropTypes from "prop-types";

function AddSection({ toggleOpen }) {
  AddSection.propTypes = {
    toggleOpen: PropTypes.func.isRequired,
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [items, setItems] = useState([
    { id: "TopProducts", component: <TopProducts />, name: "Top Products" },
    { id: "SalesColumnChart", component: <SalesColumnChart />, name: "Sales" },
    {
      id: "TwoColumnChart1",
      component: <TwoColumnChart Title={"Price Idx"} />,
      name: "Price Index",
    },
    {
      id: "TwoColumnChart2",
      component: <TwoColumnChart Title={"Price IYA"} />,
      name: "Price Index Year Ago",
    },
    {
      id: "TwoColumnChart3",
      component: <TwoColumnChart Title={"Price Idx"} />,
      name: "Price",
    },
  ]);

  const AddButton = {
    textTransform: "none",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 25,
  };

  const EditButton = {
    textTransform: "none",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 25,
  };

  const ContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
  };

  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    flexWrap: "wrap",
    marginTop: "10px",
  };

  return (
    <>
      <Box sx={ContainerStyle}>
        <Button
          sx={AddButton}
          startIcon={<AddOutlinedIcon />}
          onClick={toggleOpen}
        >
          Add New Section
        </Button>
      </Box>
    </>
  );
}

export default AddSection;
