import React from "react";
import { alpha } from "@mui/material";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import CircularLoading from "../../Charts/DemoCharts/CircularProgress";
import Progressbar from "../../Charts/DemoCharts/Progressbar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
function TotalCategory(){
    const StyledTableCell = styled(TableCell)(({ theme, sx }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontWeight:theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
            ...sx,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Total",
            p12sales: 1932347,
            p12share: 100 ,
            p12DYA: -12.8,
            AVGPrice: 10.20,
            AVGIYA: 82.7,
 
        },
        {
            name: "Corn Oil",
            p12sales: 232491,
            p12share: 9,
            p12DYA: -23.3,
            AVGPrice: 13.70,
            AVGIYA: 86.7,
 
        },
        {
            name: "Sunflower Oil",
            p12sales: 779672,
            p12share: 41,
            p12DYA: -1.3,
            AVGPrice: 9.69,
            AVGIYA: 75.9,
 
        },
        {
            name: "P&V Oil",
            p12sales: 516245,
            p12share: 29,
            p12DYA: -29.7,
            AVGPrice: 9.52,
            AVGIYA: 80.8,
 
        },
        {
            name: "Blend Oil",
            p12sales: 370336,
            p12share: 20,
            p12DYA: 0.0077,
            AVGPrice: 9.97,
            AVGIYA: 72.6,
 
        },
        {
            name: "Others",
            p12sales: 33603,
            p12share: 1,
            p12DYA: -0.13,
            AVGPrice: 10.60,
            AVGIYA: 79.7,
 
        },
    ];
    return(
        <>
        <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Total Category</Typography>
        <TableContainer >
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>P12M Value Sales</StyledTableCell>
                        <StyledTableCell>P12M Value Share (%)</StyledTableCell>
                        <StyledTableCell>P12M DYA (%)</StyledTableCell>
                        <StyledTableCell>Avg Price /Kg</StyledTableCell>
                        <StyledTableCell>Avg Price /Kg IYA</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.p12sales}</StyledTableCell>
                            <StyledTableCell>
                             <Box>
                                <Progressbar color='#008FFB' progress={item.p12share} /> {item.p12share}%
                            </Box>
                            </StyledTableCell>
                            <StyledTableCell sx={{color: item.p12DYA<0? '#ff0000':'#2cba00' }}>{item.p12DYA}</StyledTableCell>
                            <StyledTableCell>{item.AVGPrice}</StyledTableCell>
                            <StyledTableCell>
                                <ColoredBox  number={item.AVGIYA} Color="#ff4560"/>
                            </StyledTableCell>                            
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}
export default TotalCategory;