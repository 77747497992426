import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {theme} from "../../../Theme/index";
import USIcon from "../../../Assets/us.png";
import IconButton from "@mui/material/IconButton";
import { styled, alpha } from '@mui/material/styles';
import { Badge, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

function RightBar(){
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMini = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const RightBox = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "350px",
      }));
    return(
        <RightBox>           
        <IconButton aria-label="Language" sx={{ margin:"auto",}}>
         <Avatar src={USIcon} aria  sx={{width: "30px", height: "30px",}}/>
        </IconButton>
      
      <Box sx={{ display: "flex", flexDirection: "row" ,}}>
        <IconButton
          aria-label="notifications"
          sx={{ bgcolor: '#FFFAF1',width:"40px",height:"40px", margin:"auto" }}
        >
          <Badge color="accent" variant="dot" invisible={false}>
            <NotificationsNoneOutlinedIcon  sx={{ width:"30px",height:"30px",color: `${theme.palette.accent.light}` }} />
          </Badge>
        </IconButton>

       <Box sx={{ display: "flex", flexDirection: "row" , marginLeft:"10px"}}>
          <Avatar variant="circle" sx={{width:"40px", height:"40px", margin:"auto"}} src='' />
          <Box sx={{ marginLeft: "8px", marginRight: "8px" }}>
            <Typography color='primary' fontSize={isTablet?theme.typography.fontSize.xsmall:theme.typography.fontSize.small} fontWeight={theme.typography.fontWeightMedium} >
              Fady
            </Typography>
            <Typography fontSize={ isTablet?"12px":theme.typography.fontSize.xsmall} fontWeight={theme.typography.fontWeightRegular} sx={{ color: "#737791" }}>
              Admin
            </Typography>
          </Box>
          <IconButton>
            <KeyboardArrowDownIcon color='primary' />
          </IconButton>
        </Box>
        </Box>
      </RightBox>

    )
}
export default RightBar;