import React from "react";
import { Box, FormControl, Typography, Select, MenuItem } from "@mui/material";

function InputTypeBox({
  maximize,
  setMaximize,
  handleSummary,
  groupTypeOptions,
  rowData1,
}) {
  return (
    <Box sx={{ mt: 2, ml: 3 }}>
      <Typography sx={{ fontWeight: "bold", p: 1, color: "#757575" }}>
        Group Type
      </Typography>
      <FormControl sx={{ minWidth: 100, ml: 1 }} size="small">
        <Select
          value={maximize}
          onChange={(event) => {
            setMaximize(event.target.value);
            handleSummary(event.target.value, rowData1);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {groupTypeOptions.map((row) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InputTypeBox;