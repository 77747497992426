// chartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentChartType: null,
};

const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setCurrentChartType: (state, action) => {
      state.currentChartType = action.payload;
    },
  },
});

export const { setCurrentChartType } = chartSlice.actions;
export default chartSlice.reducer;