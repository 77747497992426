/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import ArrowRight from "@mui/icons-material/ArrowRight";
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../../Components/Partials/NestedMenu/DropDown";
import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentChartType } from "../../Redux/chartSlice/chartSlice";
import { setCurrentDateComparison } from "../../Redux/dateCompareSlice/dateCompareSlice";

// export const DropDownDashboard = () => {
//   return (
//     <Box>
//       <Dropdown
//         trigger={<Button>File</Button>}
//         menu={[
//           <DropdownNestedMenuItem
//             label="New"
//             rightIcon={<ArrowRight />}
//             menu={[
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log({ type: "Document", filter: "New" });
//                 }}
//               >
//                 Document
//               </DropdownMenuItem>,
//               <DropdownMenuItem>
//                 <Button
//                   component="label"
//                   sx={{
//                     color: "#000",
//                     textTransform: "none",
//                     fontSize: "1rem",
//                   }}
//                   variant="text"
//                 >
//                   From Markdown file
//                   <input
//                     id="mdInput"
//                     type="file"
//                     accept=".md"
//                     hidden
//                     onChange={(e) => {}}
//                   />
//                 </Button>
//               </DropdownMenuItem>,
//               <DropdownMenuItem>
//                 <Button
//                   component="label"
//                   sx={{
//                     color: "#000",
//                     textTransform: "none",
//                     fontSize: "1rem",
//                   }}
//                   variant="text"
//                 >
//                   From HTML file
//                   <input
//                     id="mdInput"
//                     type="file"
//                     accept=".html"
//                     hidden
//                     onChange={(e) => {}}
//                   />
//                 </Button>
//               </DropdownMenuItem>,
//             ]}
//           />,
//           <DropdownNestedMenuItem
//             label="Save as"
//             rightIcon={<ArrowRight />}
//             menu={[
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log("clicked");
//                 }}
//               >
//                 Markdown
//               </DropdownMenuItem>,
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log("clicked");
//                 }}
//               >
//                 Plain HTML
//               </DropdownMenuItem>,
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log("clicked");
//                 }}
//               >
//                 Styled HTML
//               </DropdownMenuItem>,
//             ]}
//           />,
//           <DropdownNestedMenuItem
//             label="Export"
//             rightIcon={<ArrowRight />}
//             menu={[
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log("clicked");
//                 }}
//               >
//                 PDF
//               </DropdownMenuItem>,
//               <DropdownMenuItem
//                 onClick={() => {
//                   console.log("clicked");
//                 }}
//               >
//                 Github Gist
//               </DropdownMenuItem>,
//             ]}
//           />,
//         ]}
//       />
//     </Box>
//   );
// };
// DropDownDashboard.jsx

// DropDownDashboard.jsx
/**
 * @function DropDownDashboard
 * @description A dropdown menu with a nested menu for the dashboard page.
 * @param {Object} menuConfig - The configuration for the menu.
 * @returns {ReactElement} - The dropdown menu component.
 */

/**
 * @typedef {Object} MenuConfig
 * @property {string[]} years - The year labels for the date comparison dropdown.
 * @property {string[]} months - The month labels for the date comparison dropdown.
 * @property {string[]} quarters - The quarter labels for the date comparison dropdown.
 * @property {string[]} charts - The chart type labels for the chart type dropdown.
 */
export const DropDownDashboard = ({ menuConfig }) => {
  const dispatch = useDispatch();

  const emitChartChangeEvent = (chartType) => {
    dispatch(setCurrentChartType(chartType));
  };

  const emitDateCompareChangeEvent = (dateItem) => {
    dispatch(setCurrentDateComparison(dateItem));
  };

  const handleMenuItemClick = (mainLabel, subItem) => {
    const years = menuConfig?.years || [];
    const months = menuConfig?.months || [];
    const quarters = menuConfig?.quarters || [];

    if (
      years.includes(subItem) ||
      months.includes(subItem) ||
      quarters.includes(subItem)
    ) {
      emitDateCompareChangeEvent(subItem);
    } else {
      const combinedLabel = `${mainLabel},${subItem}`;
      emitChartChangeEvent(combinedLabel); // Emit the combined label
    }
  };

  return (
    <Box>
      <Dropdown
        trigger={<Button>Charts Filters</Button>}
        menu={Object.entries(menuConfig)
          .filter(([key, value]) => value !== null)
          .map(([mainLabel, items], index) => (
            <DropdownNestedMenuItem
              key={mainLabel}
              label={mainLabel}
              rightIcon={<ArrowRight />}
              menu={items.map((subItem, subIndex) => (
                <DropdownMenuItem
                  key={`${mainLabel}-${subIndex}`}
                  onClick={() => handleMenuItemClick(mainLabel, subItem)} // Pass mainLabel and subItem
                >
                  {subItem}
                </DropdownMenuItem>
              ))}
            />
          ))}
      />
    </Box>
  );
};
