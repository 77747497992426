import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "src/Components/Partials/Navbar/Navbar_2";
import { Typewriter } from "react-simple-typewriter";
import { getUrl } from "src/Utils/Common";
import axios from "axios";

const EmailLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleEmailLogin = async () => {
    if (email && password) {
      try {
        const response = await axios.post(
          `${getUrl()}/auth/login`,
          // Body as a plain URLSearchParams object
          new URLSearchParams({
            email: email,
            password: password,
          }).toString(),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", // Match backend expectations
              "x-cors-api-key": "temp_8c8f91f122df0ef9daa49a6701f13f76",
            },
          }
        );

        if (response.status === 201) {
          console.log("Login successful:", response.data);
          const data = await response.data;
          console.log("Login successful:", data);

          // Store the token in localStorage
          localStorage.setItem("userId", data.token);

          // Navigate to the next page
          navigate("/select-user-id");
        } else if (response.status === 401) {
          alert("Invalid email or password.");
        } else {
          alert("An unexpected error occurred.");
        }
      } catch (err) {
        console.error("Error logging in:", err);
        alert("Failed to connect to the server.");
      }
    } else {
      alert("Please enter both email and password.");
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          width: 400,
          margin: "auto",
          marginTop: 5,
          marginBottom: 10,
          padding: 4,
          borderRadius: 2,
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 4,
            fontFamily: "'Courier New', Courier, monospace",
            fontWeight: "bold",
            color: "primary.main",
          }}
        >
          <Typewriter
            words={[
              "Welcome to Marketeers Research.",
              "Your gateway to data-driven insights.",
              "Empowering solutions for your business.",
            ]}
            loop={true}
            cursor
            cursorStyle="|"
            typeSpeed={50}
            deleteSpeed={30}
            delaySpeed={2000}
          />
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            fontWeight: "bold",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          Login with Email
        </Typography>
        {error && (
          <Typography color="error" sx={{ marginBottom: 2 }}>
            {error}
          </Typography>
        )}
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleEmailLogin}
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Login
        </Button>
      </Box>
    </>
  );
};

export default EmailLogin;
