import { useCustomSensors } from "../../DragAndDrop/DragHandlers";
import { SortableChart } from "../StatisticsTest/components/SortableChart";
import { Box, Typography, Paper, Grid } from "@mui/material";
import React, { useState } from "react";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import { theme } from "../../Theme";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";

import { onDragEndHandler } from "../../DragAndDrop/SortableHandlers";
import { data } from "./data";

function TradeManagement() {
  const [items, setItems] = useState({
    addSection: [],
  });
  const [users, setUsers] = useState(data);

  const onDragEnd = (event) => {
    onDragEndHandler(event, setUsers, users);
  };
  const onDragStartHandler = (event) => {
    setActiveId(event.active.id);
  };

  const onDragCancelHandler = () => {
    setActiveId(null);
  };
  function RemoveComponent(id) {
    let itemToMove;

    setUsers((prevUsers) => {
      itemToMove = prevUsers.find((item) => item.id === id);
      const updatedMainDash = prevUsers.filter((item) => item.id !== id);
      setItems((prevItems) => {
        const updatedAddSection = [...prevItems.addSection, itemToMove];

        return {
          ...prevItems,
          addSection: updatedAddSection,
        };
      });
      return updatedMainDash;
    });
  }
  // const [items, setItems] = useState({
  //   addSection: [
  //     {
  //       id: "SalesColumnChart",
  //       component: <SalesColumnChart />,
  //       name: "Sales",
  //       xs: 12,
  //       md: 4,
  //     },
  //     {
  //       id: "TwoColumnChart2",
  //       component: <TwoColumnChart Title={"Price IYA"} />,
  //       name: "Price Index Year Ago",
  //       xs: 12,
  //       md: 3,
  //     },
  //     {
  //       id: "TwoColumnChart3",
  //       component: <TwoColumnChart Title={"Price Idx"} />,
  //       name: "Price",
  //       xs: 12,
  //       md: 3,
  //     },
  //     {
  //       id: "TopProd2",
  //       component: <TopProd sx={{ flex: 1 }} />,
  //       name: "Top Products",
  //       xs: 12,
  //       md: 6,
  //     },
  //     {
  //       id: "SourceShare2",
  //       component: <SourceShareTable />,
  //       name: "Source Share",
  //       xs: 12,
  //       md: 6,
  //     },
  //     {
  //       id: "CategoryOverview",
  //       component: <CategoryOverview />,
  //       name: "Category Overview",
  //       xs: 12,
  //       md: 12,
  //     },
  //   ],
  //   mainDash: [
  //     {
  //       id: "TotalCategory",
  //       component: <TotalCategory />,
  //       name: "Total Category",
  //       xs: 12,
  //       md: 6,
  //     },
  //     { id: "Savola", component: <Savola />, name: "Savola", xs: 12, md: 6 },
  //     {
  //       id: "SunflowerBrandInteraction",
  //       component: <SFBrandInteraction />,
  //       name: "Sunflower Brand Interaction",
  //       xs: 12,
  //       md: 6,
  //     },
  //     {
  //       id: "CornBrandInteraction",
  //       component: <CornBrandInteraction />,
  //       name: "Corn Brand Interaction",
  //       xs: 12,
  //       md: 6,
  //     },
  //     {
  //       id: "PricingStrategy",
  //       component: <PricingStrategy />,
  //       name: "Pricing Strategy",
  //       xs: 12,
  //       md: 6,
  //     },
  //     {
  //       id: "PriceElasticity",
  //       component: <PriceElasticity />,
  //       name: "Price Elasticity",
  //       xs: 12,
  //       md: 6,
  //     },
  //   ],
  // });
  const [activeId, setActiveId] = useState();
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
  const sensors = useCustomSensors();

  // const handleDragStartWrapper = (event) => handleDragStart(event, setActiveId);
  // const handleDragOverWrapper = (event) =>
  //   handleDragOver(event, items, setItems, findContainer);
  // const handleDragEndWrapper = (event) =>
  //   handleDragEnd(event, items, setItems, setActiveId, findContainer);
  const toggleAddSection = () => {
    setIsAddSectionOpen(!isAddSectionOpen);
  };

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
  };
  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };

  return (
    <>
      {/*  <Box>

         <TotalCategory/>
         <Savola/>
         <CategoryOverview/>
         // <ComparisonTable/> 
         <PriceElasticity/>
         <PricingStrategy/>
         <SFBrandInteraction/>
     </Box> */}
      <Box>
        <AddSection sx={{ m: 2 }} toggleOpen={toggleAddSection} />
        {isAddSectionOpen && (
          <Box component={Paper} sx={ComponentBar}>
            <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <DroppableAddSection
                id="addSection"
                children={items.addSection}
                items={items.addSection}
                setUsers={setUsers}
                setItems={setItems}
              />
            </Box>
          </Box>
        )}
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
          sensors={sensors}
          onDragStart={onDragStartHandler}
          onDragCancel={onDragCancelHandler}
        >
          <SortableContext items={users} strategy={rectSortingStrategy}>
            <Typography sx={TitleStyle}>Market Brands Overview</Typography>
            <Grid container spacing={2}>
              {users.map((user) => (
                <Grid item xs={user.xs} sm={6} md={user.md} key={user.id}>
                  <SortableChart
                    user={user}
                    RemoveComponent={() => RemoveComponent(user.id)}
                  />
                </Grid>
              ))}
            </Grid>
            {/* <DroppableGrid
              id="mainDash"
              children={items.mainDash}
              RemoveComponent={RemoveComponent}
            /> */}
            {/* <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay> */}
          </SortableContext>
        </DndContext>
      </Box>
    </>
  );
}
export default TradeManagement;
