import BarChartWob from "../../Components/Charts/ActualVsExpectedMixChart/BarChartWob";
import TwoColumnChart from "../../Components/Charts/TwoColumnChart/TwoColumnChart";
import SalesColumnChart from "../../Components/Charts/SalesColumnChart/SalesColumnChart";
import BrandSales from "../../Components/Charts/ActualVsExpectedMixChart/BrandSales";

import TwoColumnChartPriceIndex from "src/Components/Charts/twoColumnChartPriceIndex/twoColumnChartPriceIndex";

  import BarChartWobContribution from "src/Components/Charts/BarChartWobContribution/BarChartWobContribution";


export const data =  [
    {
      id: "TwoColumnChart1",
      component: (
        <TwoColumnChartPriceIndex
        title={"Value Sales Growth vs. YO"}
        />
      ),
      name: "Price Index",
      xs: 12,
      md: 4,
    },
    // {
    //   id: "valueSalesChart",
    //   component: (
    //     <TwoColumnChart
    //       dataValues={valueSalesYA}
    //       title={"Value Sales Growth vs. YO"}
    //       // minValue={0}
    //       // maxValue={20}
    //     />
    //   ),
    //   name: "Value Sales YA",
    //   xs: 12,
    //   md: 4,
    // },
    {
      id: "volumeSalesChart",
      component: (
        <TwoColumnChart/>
      ),
      name: "Value Sales YA",
      xs: 12,
      md: 4,
    },
    {
      id: "BrandSales",
      component: <BrandSales/>,
      name: "Brand Sales",
      xs: 12,
      md: 12,
    },
    {
      id: "WobData",
      component: (
        <BarChartWob/>
      ),
      name: "WobData",
      xs: 12,
      md: 6,
    },
    {
      id: "ContributionData",
      component: (
        <BarChartWobContribution/>
      ),
      name: "ContributionData",
      xs: 12,
      md: 6,
    }
  
  ]