import { Box } from "@mui/material";

function ReversedProgressBar({
  bgcolor,
  progressChild,
  number,
}) {
  const progressParent = {
    display: "flex",
    height: "10px",
    borderRadius: "25px",
    marginY: "5px",
    transform: "scaleX(-1)",  // Reverse the direction of the progress bar
    overflow: "hidden",
    width: "100%", // Ensure the parent container spans the full width
  };

  const Childdiv = {
    width: `${progressChild}%`,
    backgroundColor: bgcolor,
    borderRadius: "25px",
    minWidth: "50px",
    transform: "scaleX(-1)",  // Counteract the reversal effect on the child
  };

  const progresstext = {
    paddingLeft: "10px",
    textAlign: "left",
  };

  return (
    <Box sx={progressParent}>
      <div style={Childdiv}>
        <p style={progresstext}>{number}</p>
      </div>
    </Box>
  );
}

export default ReversedProgressBar;
