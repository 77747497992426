import { Box, Paper } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import InfoBar from "../Components/Partials/Infobar/InfoBar";

function DraggableCard({ id, child, RemoveComponent }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useDraggable({ id });

  const BoxStyle = {
    display: "flex",
    flexDirection: "column",
    height: 270,
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
  };

  return (
    <Box
      component={Paper}
      ref={setNodeRef}
      sx={BoxStyle}
      {...listeners}
      {...attributes}
      RemoveComponent={() => RemoveComponent(id)}
    >
      <InfoBar />
      {child.component}
    </Box>
  );
}

export default DraggableCard;
