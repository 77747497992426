import SourceShareTable from "../../Components/Tables/SourceShareTable/SourceShareTable";
import UnknownMixChart from "../../Components/Charts/UnknownMixChart/UnknownMixChart";
import BrandShare from "../../Components/Tables/BrandShareTable/BrandShare";
import React from 'react';

const secondGapData=[{
  name: 'Base Market Share',
  type: 'column',
  data: [4.96]
}, {
  name: 'New Market Share',
  type: 'column',
  data: [5.76]
},]
const secondGapNames=["Afia Corn 1.5L"]

export const data = [
    {
      id: 1,
      name: "Samaria",
      component: <SourceShareTable />,
      xs: 12,
      md: 6,
      order: 1,
    },
    {
      id: 2,
      name: "Gauthier",
      component:  <UnknownMixChart data={secondGapData} names={secondGapNames} minValue={0} maxValue={7} />,
      xs: 12,
      md: 6,
      order: 2,
    },
    {
      id: 3,
      name: "Mellisa",
      component: <BrandShare />,
      md:8,
xs:12,
 order: 3,
    },    {
        id: 4,
        name: "Melalisa",
        component: <BrandShare />,
        md:8,
xs:12,
         order: 4,
      }
 
  ];