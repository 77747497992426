import { ContentPasteOffOutlined } from "@mui/icons-material";
import axiosClient from "../apiClient";

export async function getElasticity(data) {
  const response = await axiosClient.post("/analysis/elasticity_2", data);
  return response;
}

export async function getOptimization(data) {
  const response = await axiosClient.post("/analysis/pricepromotion", data);
  return response;
}

export async function getProjectData(data) {
  const response = await axiosClient.get("/sim/get_project_by_name?", { params: data });
  return response;
}

export async function editSopByGroup(data) {
  const response = await axiosClient.post("/sop/editsopbyvalue", data);
  return response;
}

export async function brandOptimization() {
  const response = await axiosClient.post("analysis/brandsOfProjects", {
    project_name:
      "savola smart shopper oil category traditional",
  });
  return response;
}

export async function skuOptimization(data) {
  try {
    let result = await axiosClient.post("analysis/boundaries", {
      project_name: "savola smart shopper oil category traditional",
      brand: data,
    });

    return result;
  } catch (error) {
    console.error("error");
  }
}

export async function bestOptimization(skus, objective) {
  try {
    let result = await axiosClient.post("analysis/optimization", {
      project: "araby6",
      skus_list: skus,
      objective: objective,
    });

    return result;
  } catch (error) {
    console.error("error");
  }
}

export async function applyScenario(data) {
  try {
    let result = await axiosClient.post("sim/scenario_w_stats", data);

    return result;
  } catch (error) {
    console.error("error");
  }
}