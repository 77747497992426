import ReactApexChart from "react-apexcharts";
import { theme } from "../../../Theme/index";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FacebookCircularProgress from "../../shared/Loading/Loading";
import { getUrl } from "src/Utils/Common";

function BrandSales() {
  const currentChartType = useSelector((state) => state.chart.currentChartType);

  useEffect(() => {
    console.log(`Current chart type in Dashboard: ${currentChartType}`);
  }, [currentChartType]);

  const currentDateComparison = useSelector(
    (state) => state.dateCompare.currentDateComparison
  );

  // Get projectId from Redux store
  const projectId = useSelector((state) => state.userProject.projectId);

  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!projectId) {
        console.warn("Project ID is not set in Redux state.");
        return; // Exit if projectId is not available
      }

      // Parse hierarchy and hierarchy_value from currentChartType
      const [hierarchy, hierarchy_value] = currentChartType?.split(",") || [
        "brand",
        "AFIA",
      ];

      if (!hierarchy || !hierarchy_value) {
        console.warn(
          "Invalid chart type format. Expected 'hierarchy,hierarchy_value'."
        );
        return; // Exit if hierarchy or hierarchy_value is not available
      }

      try {
        const response = await fetch(
          `${getUrl()}/market_overview/brand_performance`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              project_id: projectId, // Use projectId from Redux
              hierarchy, // Use parsed hierarchy
              hierarchy_value, // Use parsed hierarchy_value
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        console.log("API Response:", result);

        if (Array.isArray(result) && result.length > 0) {
          const transformedData = result
            .filter((item) => item.name !== "date") // Filter out the "date" column
            .map((item) => ({
              name: item.name,
              type: item.name === hierarchy_value ? "line" : "bar",
              data: item.data,
            }));

          const xAxisCategories = result.find(
            (item) => item.name === "date"
          )?.data;

          setData(transformedData);
          setCategories(xAxisCategories || []); // Set an empty array if categories are undefined
        } else {
          console.error("Unexpected API response structure.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [currentChartType, currentDateComparison, projectId]); // Re-run when currentChartType changes

  const getYAxisFormatter = (val) => {
    const formattedAmount = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(val);
    return formattedAmount;
  };

  const [options] = useState({
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#7735FD"],
    xaxis: {
      categories: categories,
      tooltip: {
        enabled: true,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#DCDCDC",
      position: "back",
    },
    yaxis: {
      seriesName: "bar",
      min: 0,
      title: {
        text: "Value Sales (SR)",
      },
      labels: {
        formatter: getYAxisFormatter,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%", // Adjust column width
        borderRadius: 0, // Adjust border radius if needed
        dataLabels: {
          position: "top", // Place data labels on top of the bars
        },
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
      {
        breakpoint: 3900,
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
    ],
  });

  return (
    <>
      <Typography fontWeight={theme.typography.fontWeightBold} px={1}>
        Brand Performance 2022-2023
      </Typography>
      {data.length > 0 ? (
        <ReactApexChart
          options={{ ...options, xaxis: { ...options.xaxis, categories } }}
          series={data}
          height={250}
          width={1050}
        />
      ) : (
        <FacebookCircularProgress />
      )}
    </>
  );
}

export default BrandSales;
