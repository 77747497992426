import { createTheme } from "@mui/material/styles";
import "@fontsource/poppins";

let theme = createTheme({
  palette: {
    primary: {
      main: "#00265E", // navy blue
      light: "#5750FF", // vibrant blue
      dark: "#666666", // dark grey
    },
    secondary: {
      main: "#FFFFFF", // white
      dark: "#B3B3B3", // grey
      light: "#F2F4F7", // light grey
    },
    accent: {
      main: "#b60002", // red
      light: "#fbc210", // yellow
    },
  },

  typography: {
    fontFamily: "Poppins",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontSize: {
      xxsmall: "12px",
      xsmall: "14px",
      small: "18px",
      medium: "20px",
      large: "28px",
      xlarge: "36px",
    },
  },

  spacing: (factor) => `${factor * 8}px`, // Default spacing based on 8px scale, can be used for padding/margin

  // Custom padding and margin values for global use
  customSpacing: {
    padding: {
      xsmall: "4px",
      small: "8px",
      medium: "16px",
      large: "24px",
      xlarge: "32px",
    },
    margin: {
      xsmall: "4px",
      small: "8px",
      medium: "16px",
      large: "24px",
      xlarge: "32px",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export { theme };
