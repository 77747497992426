import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ children }) => {
  const projectId = useSelector((state) => state.userProject.projectId);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("userId"); // Get the token from local storage
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsCheckingAuth(false); // Authentication check completed
  }, []);

  if (isCheckingAuth) {
    return <div>Loading...</div>; // Optional: Replace with a loader/spinner component
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirect to login if no token is found
  }

  if (!projectId) {
    return <Navigate to="/select-user-id" />; // Redirect to project selection if no projectId is found
  }

  return children; // Allow access if authenticated and projectId is set
};

export default RequireAuth;
