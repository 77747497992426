import React, { useState } from 'react';
import { Box, Grid, Typography, Paper } from "@mui/material";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from "../../Theme/index";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BrandSales from "../../Components/Charts/ActualVsExpectedMixChart/BrandSales";
import TopProd from "../../Components/Tables/Top2/TopProd";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";
import { arrayMove } from '@dnd-kit/sortable';
import DroppableGrid from "../../DragAndDrop/DroppableGrid";
import { DndContext } from '@dnd-kit/core';
import { findContainer, useCustomSensors } from '../../DragAndDrop/DragHandlers';
import SourceShareTable from "../../Components/Tables/SourceShareTable/SourceShareTable";

function MarketDynamics(){
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const [activeId, setActiveId] = useState();
    const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);

    const [items, setItems] = useState({
        addSection: [
            { id: 'TopProd2', component: <TopProd sx={{ flex: 1 }} />, name: "Top Products", xs: 6 },
            { id: 'SourceShare2', component: <SourceShareTable />, name: "Source Share", xs: 6 },
        ],
        mainDash: [
            { id: 'BrandSales', component: <BrandSales />, name: "Brand Sales", xs: 6 },
            { id: 'TopProd', component: <TopProd sx={{ flex: 1 }} />, name: "Top Products", xs: 6 },
            { id: 'SourceShare', component: <SourceShareTable />, name: "Source Share", xs: 6 },
        ]
    });

    const sensors = useCustomSensors();

    const handleDragEnd = (event) => {
      const { active, over } = event;
      if (!over) return;
    
      const { id } = active;
      const { id: overId } = over;
    
      const activeContainer = findContainer(id, items);
      const overContainer = findContainer(overId, items);
    
      if (!activeContainer || !overContainer || activeContainer !== overContainer) {
        return;
      }
    
      const activeIndex = items[activeContainer].findIndex(item => item.id === active.id);
      const overIndex = items[overContainer].findIndex(item => item.id === overId);
    
      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
        }));
      }
    
      setActiveId(null);
    
    };

  
    const toggleAddSection = () => {
        setIsAddSectionOpen(!isAddSectionOpen);
    };

    const TitleStyle = {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize.medium,
        paddingY: 1,
        color: theme.palette.primary.main,
    };
    const ComponentBar = {
        backgroundColor: theme.palette.secondary.main,
        marginTop: '10px',
        padding: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    };
    const DeleteButton = {
        alignSelf: "flex-end",
        padding: 0,
        margin: 0,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        cursor: "pointer",
    };

    return (
        <Box>
            <AddSection toggleOpen={toggleAddSection} />

            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                {isAddSectionOpen && (
                    <Box component={Paper} sx={ComponentBar}>
                        <CloseOutlinedIcon sx={DeleteButton} onClick={toggleAddSection} />
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <DroppableAddSection id="addSection" children={items.addSection} />
                        </Box>
                    </Box>
                )}
                <Typography sx={TitleStyle}>Market vs Company</Typography>
                <DroppableGrid id="mainDash" children={items.mainDash} />
            </DndContext>
        </Box>
    );
}

export default MarketDynamics;
