import React from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { theme } from "../../../Theme/index";
import InfoBar from "../../Partials/Infobar/InfoBar";
import Progressbar from "../../Charts/DemoCharts/Progressbar";
import ColoredBox from "../../Boxes/TopProductBox/ColoredBox";
function Savola(){
    const StyledTableCell = styled(TableCell)(({ theme, sx }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: "5px",
            color: theme.palette.primary.dark,
            fontWeight:theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSize.xxsmall,
            textAlign: "center",
            justifyContent: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            textTransform: "capitalize",
            color: theme.palette.primary.dark,
            fontSize: 10,
            textAlign: "center",
            padding: "3px",
            ...sx,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // Reduce the row height
        height: 30,
    }));
    
    const list = [
        {
            name: "Total",
            p12sales: 981859,
            p12share: 50 ,
            p12DYA: -28,
            AVGPrice: 10.8,
            AVGIYA: 88.5
 
        },
        {
            name: "Corn Oil",
            p12sales: 187613,
            p12share: 9.6,
            p12DYA: -0.26,
            AVGPrice: 15,
            AVGIYA: 88,
 
        },
        {
            name: "Sunflower Oil",
            p12sales: 253659,
            p12share: 12.8,
            p12DYA: -0.41,
            AVGPrice: 10,
            AVGIYA: 84,
 
        },
        {
            name: "P&V Oil",
            p12sales: 339588,
            p12share: 17.1,
            p12DYA: -0.34,
            AVGPrice: 9.8,
            AVGIYA: 84,
 
        },
        {
            name: "Blend Oil",
            p12sales: 98854,
            p12share: 5.3,
            p12DYA: 0.4,
            AVGPrice: 105,
            AVGIYA: 98.8,
 
        },
        {
            name: "Others",
            p12sales: 23028,
            p12share: 1.2,
            p12DYA: -0.26,
            AVGPrice: 13.0,
            AVGIYA: 83,
 
        },
    ];
    return(
        <>
        <Typography fontWeight={theme.typography.fontWeightBold} p={1}>Savola</Typography>
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>P12M Value Sales</StyledTableCell>
                        <StyledTableCell>P12M Value Share (%)</StyledTableCell>
                        <StyledTableCell>P12M DYA (%)</StyledTableCell>
                        <StyledTableCell>Avg Price /Kg</StyledTableCell>
                        <StyledTableCell>Avg Price /Kg IYA</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.p12sales}</StyledTableCell>
                            <StyledTableCell>                             <Box>
                                <Progressbar color='#008FFB' progress={item.p12share} /> {item.p12share}%
                            </Box></StyledTableCell>
                            <StyledTableCell sx={{color: item.p12DYA<0? '#ff0000':'#2cba00' }}>{item.p12DYA}</StyledTableCell>
                            <StyledTableCell>{item.AVGPrice}</StyledTableCell>
                            <StyledTableCell> <ColoredBox  number={item.AVGIYA} Color="#008FFB"/></StyledTableCell>                            
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}
export default Savola;