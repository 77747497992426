import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Menu from "@mui/material/Menu";
import * as React from "react";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { theme } from "../../../Theme/index";
import { Box, IconButton } from "@mui/material";

function InfoBar({ attributes, listeners, RemoveComponent }) {
  const StyledBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 1,
    paddingX: 1,
  };
  const StyledMore = {
    cursor: "pointer",
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);
  const handleMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMore = () => {
    setAnchorEl(null);
  };

  const dragStyle = {
    cursor: "pointer",
    outline: "none",
  };
  const MenuItemStyle = {
    fontSize: 15,
    p: 1,
  };
  return (
    <Box sx={StyledBox}>
      {/* <IconButton></IconButton> */}
      <DragIndicatorOutlinedIcon
        sx={dragStyle}
        {...attributes}
        {...listeners}
      />
      <Box>
        <MoreVertOutlinedIcon sx={StyledMore} onClick={handleMore} />
        <Menu
          open={openMore}
          anchorEl={anchorEl}
          onClose={closeMore}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            autoFocusItem: false,
            sx: { width: 130 },
          }}
        >
          <MenuItem onClick={closeMore} sx={MenuItemStyle}>
            Download svg
          </MenuItem>
          <MenuItem onClick={closeMore} sx={MenuItemStyle}>
            Download png
          </MenuItem>
          <MenuItem onClick={RemoveComponent} sx={MenuItemStyle}>
            Remove Chart
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default InfoBar;
