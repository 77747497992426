import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { theme } from "../../../Theme/index";
import { getUrl } from "src/Utils/Common";

function CannibalizationTable() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    async function fetchData() {
      const TIMEOUT = 1000000; // Set timeout to 10 seconds (10000ms)
  
      // Create a promise that rejects after the timeout
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), TIMEOUT)
      );
  
      try {
        // Use Promise.race to race between the fetch and the timeout
        const response = await Promise.race([
          fetch(`${getUrl()}/sim/strategy_table`, {
            method: "POST",
            headers: { "Content-Type": "application/json",
              'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
             },
            body: JSON.stringify({ project_name: "newsavola2" }),
          }),
          timeoutPromise,
        ]);
  
        if (!response.ok) {
          throw new Error(`API error: ${response.statusText}`);
        }
  
        const data = await response.json();

        // Map the API response to table rows
        const mappedData = data.map((item) => ({
          skuName: item["SKU Name"],
          cannibalization: Math.abs(item.cannibalization * 100).toFixed(2), // Convert to percentage
          topSku: item.top_sku_in_interaction,
          strategy: item.incr_role,
          volumeDriver: item.volume_driver,
          valueDriver: item.value_driver,
          minPrice: item.with_lower_price ? "✔" : "✘",
          maxPrice: item.with_higher_price ? "✔" : "✘",
        }));

        setRows(mappedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // Helper function to color code cannibalization
  const getColor = (value) => {
    const numericValue = parseFloat(value);
    if (numericValue >= 70) return "#FF6347"; // Red for high cannibalization
    if (numericValue >= 60) return "#7392f8"; // Yellow for medium cannibalization
    return "#90EE90"; // Green for low cannibalization
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" component="h2" gutterBottom>
        SKU Cannibalization Table
      </Typography>
      <TableContainer component={Paper} sx={{ height: 400 }}>
        <Table>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 10,
            }}
          >
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>SKU Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Cannibalization (%)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Top SKU in Interaction
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Strategy</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Volume Driver
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Value Driver
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Min Price
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Max Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ height: "24px" }}>
                <TableCell
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.skuName}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                  style={{
                    backgroundColor: getColor(row.cannibalization),
                    color: "#000",
                  }}
                >
                  {row.cannibalization}%
                </TableCell>
                <TableCell
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.topSku}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.strategy}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.volumeDriver && <CheckIcon color="success" />}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.valueDriver && <CheckIcon color="success" />}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.minPrice}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  {row.maxPrice}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CannibalizationTable;
