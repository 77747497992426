import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { renderToString } from "react-dom/server";
import FacebookCircularProgress from "../../shared/Loading/Loading";
import { getUrl } from "src/Utils/Common";

function TwoColumnChartPriceIndex() {
  const currentDateComparison = useSelector(
    (state) => state.dateCompare.currentDateComparison
  );
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  // State for the title and data
  const [title, setTitle] = useState("Price IYA");
  const [dataValues, setDataValues] = useState([]);
  const [categories, setCategories] = useState([
    "Price_idx_YO_Category",
    "Price_idx_YO_AFIA",
  ]);

  // Get project_id from Redux and sales_type
  const projectId = useSelector((state) => state.userProject.projectId);
  // const [salesType, setSalesType] = useState("value_sales"); // Default sales type

  useEffect(() => {
    async function fetchMarketBrandSales() {
      if (!projectId) {
        console.warn("Project ID is not set in Redux state.");
        return;
      }

      try {
        const response = await fetch(
          `${getUrl()}/market_overview/brand_market_pidx`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
            },
            body: JSON.stringify({
              project_id: projectId,
              brand: "AFIA",
              period_level: "months",
              market: "KSA oil base",
              area: null,
              target: 7,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        // Update chart title based on sales_type
        // const updatedTitle =
        //   salesType === "value_sales"
        //     ? "Price Value Sales Growth vs. YO"
        //     : "Price Volume Sales Growth vs. YO";

        // Update the chart data
        // setTitle(updatedTitle);
        // setDataValues([
        //   {
        //     name: "Category Growth",
        //     data: [result.Category_Growth[0]],
        //   },
        //   {
        //     name: "AFIA Growth",
        //     data: [result.AFIA_growth[0]],
        //   },
        // ]);

        // Calculate min and max values
        const fetchedMin = Math.min(
          ...result.Price_idx_YO_Category,
          ...result.Price_idx_YO_AFIA
        );
        const fetchedMax = Math.max(
          ...result.Price_idx_YO_Category,
          ...result.Price_idx_YO_AFIA
        );
        setMinValue(fetchedMin * 10.0);
        setMaxValue(fetchedMax * 10.0);

        setDataValues([
          {
            name: "Volume Sales YA",
            data: [
              result.Price_idx_YO_Category[0],
              result.Price_idx_YO_AFIA[0],
            ], // Update the values
          },
        ]);
      } catch (error) {
        console.error("Error fetching market brand sales data:", error);
      }
    }

    fetchMarketBrandSales();
  }, [projectId]); // Re-fetch when projectId or salesType changes

  // Update dataValues when currentDateComparison changes
  // useEffect(() => {
  //   const x = Math.random().toFixed(2); // Generate a random decimal
  //   const y = Math.random().toFixed(2); // Generate another random decimal

  //   setDataValues([
  //     {
  //       name: "Price IYA",
  //       data: [x, y], // Update the values
  //     },
  //   ]);
  //   console.log(`Current date comparison: ${currentDateComparison}`);
  // }, [currentDateComparison]);

  // Chart options
  const [options] = useState({
    chart: {
      type: "bar",
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val.toFixed(2)}%`; // Format labels as percentages
      },
      offsetY: 100,
      style: {
        fontSize: "11px",
        colors: ["#304758"],
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
        endingShape: "rounded",
        borderRadius: 6,
        distributed: true,
      },
    },
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: categories,
      labels: {
        show: false, // Remove category labels under the bars
        fontSize: "9px",
        fontWeight: 500,
      },
      tooltip: {
        enabled: true,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: minValue, // Dynamically set min value
      max: maxValue, // Dynamically set max value
      title: {
        text: "Growth (%)",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      offsetX: 5,
      offsetY: 5,
      markers: {
        radius: 25,
      },
    },
  });

  function customFormatter(seriesName, opts) {
    const dataPointIndex = opts.seriesIndex; // Index of the current data point (category)
    const value = opts.w.globals.series[0][dataPointIndex]; // Retrieve the value for the current data point

    const jsxString = renderToString(
      <Box marginX={1}>
        <Typography sx={{ fontWeight: "800" }}>{seriesName}</Typography>
        <Typography>{value} M</Typography>
      </Box>
    );

    return `${seriesName}: ${value}`;
  }

  return (
    <>
      <Typography fontWeight="bold" px={1}>
        {title}
      </Typography>
      {dataValues.length > 0 ? (
        <ReactApexChart
          options={options}
          series={dataValues}
          type="bar"
          height={200}
        />
      ) : (
        <FacebookCircularProgress />
      )}
    </>
  );
}

export default TwoColumnChartPriceIndex;
