import React, { useState, useEffect } from "react";
import "./charts.css";
import { data } from "./data";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SortableChart } from "./components/SortableChart";
import { useCustomSensors } from "../../DragAndDrop/DragHandlers";
import { theme } from "../../Theme";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Paper } from "@mui/material";
import DroppableAddSection from "../../DragAndDrop/DroppableAddSection";
import { onDragEndHandler } from "../../DragAndDrop/SortableHandlers";

// import { useDispatch, useSelector } from "react-redux";
// import { setUserProject } from "../../Redux/userPojectSlice/userProjectSlice";
const Users = () => {
  // const dispatch = useDispatch();
  // const { userId, projectId } = useSelector((state) => state.userProject);

  // const handleSetUserProject = () => {
  //   dispatch(setUserProject({ userId: "123", projectId: "456" }));
  // };

  const [activeId, setActiveId] = useState();
  const sensors = useCustomSensors();

  const [users, setUsers] = useState(data);
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
  const toggleAddSection = () => {
    setIsAddSectionOpen(!isAddSectionOpen);
  };
  const [items, setItems] = useState({
    addSection: [],
  });
  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    mb: 3,
    mt: 1,
    mx: 2,
  };
  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };
  const onDragEnd = (event) => {
    onDragEndHandler(event, setUsers, users);
  };
  const onDragStartHandler = (event) => {
    setActiveId(event.active.id);
  };

  const onDragCancelHandler = () => {
    setActiveId(null);
  };
  function RemoveComponent(id) {
    let itemToMove;

    setUsers((prevUsers) => {
      itemToMove = prevUsers.find((item) => item.id === id);
      const updatedMainDash = prevUsers.filter((item) => item.id !== id);
      setItems((prevItems) => {
        const updatedAddSection = [...prevItems.addSection, itemToMove];

        return {
          ...prevItems,
          addSection: updatedAddSection,
        };
      });
      return updatedMainDash;
    });
  }

  const [templates, setTemplates] = useState([]); // Store saved templates

  const saveTemplate = () => {
    const templateName = prompt("Enter a name for this layout:");
    if (templateName) {
      const newTemplate = {
        name: templateName,
        layout: users.map(({ id, xs, md }) => ({ id, xs, md })), // Save only layout data, no component
      };
      setTemplates([...templates, newTemplate]);
      localStorage.setItem(
        "templates",
        JSON.stringify([...templates, newTemplate])
      );
    }
  };

  const loadTemplate = (template) => {
    const restoredLayout = template.layout.map((savedUser) => {
      const user = data.find((u) => u.id === savedUser.id); // Find the original user data
      return { ...user, xs: savedUser.xs, md: savedUser.md }; // Merge with saved layout
    });
    setUsers(restoredLayout);
  };

  useEffect(() => {
    const storedTemplates = JSON.parse(localStorage.getItem("templates"));
    if (storedTemplates) {
      setTemplates(storedTemplates); // Load saved templates on page load
    }
  }, []);
  return (
    <div className="users-container">
      <AddSection sx={{ m: 2 }} toggleOpen={toggleAddSection} />
      {isAddSectionOpen && (
        <Box component={Paper} sx={ComponentBar}>
          <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <DroppableAddSection
              id="addSection"
              children={items.addSection}
              items={items.addSection}
              setUsers={setUsers}
              setItems={setItems}
            />
          </Box>
        </Box>
      )}
      <div>
        <Button
          sx={{ mt: 2, mx: 2 }}
          onClick={saveTemplate}
          variant="contained"
          color="primary"
        >
          Save Layout as Template
        </Button>
      </div>
      <div>
        <Typography
          sx={{ ml: 2, mt: 3, fontWeight: theme.typography.fontWeightBold }}
          variant="h5"
        >
          Saved Templates
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",

            flexGrow: 1,
            marginTop: "8px",
          }}
        >
          {templates.map((template, index) => (
            <Button
              sx={{
                mb: 3,
                ml: 1,
                fontSize: theme.typography.fontSize.medium,
              }}
              key={index}
              onClick={() => loadTemplate(template)}
            >
              {template.name}
            </Button>
          ))}
        </div>
      </div>{" "}
      <div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
          sensors={sensors}
          onDragStart={onDragStartHandler}
          onDragCancel={onDragCancelHandler}
        >
          <SortableContext items={users} strategy={rectSortingStrategy}>
            <Grid container spacing={2}>
              {users.map((user) => (
                <Grid item xs={user.xs} sm={6} md={user.md} key={user.id}>
                  <SortableChart
                    user={user}
                    RemoveComponent={() => RemoveComponent(user.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </SortableContext>
        </DndContext>
      </div>
      {/* <div>
        <p>User ID: {userId}</p>
        <p>Project ID: {projectId}</p>
        <button onClick={handleSetUserProject}>Set User and Project</button>
      </div> */}
    </div>
  );
};

export default Users;
