import { Box, Paper, Typography } from "@mui/material";
import { renderToString } from 'react-dom/server';
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import chroma from "chroma-js";
import {theme} from "../../../Theme/index"
import InfoBar from "../../Partials/Infobar/InfoBar";

function SalesColumnChart(){
    // const colors =[theme.palette.accent.light,theme.palette.primary.dark, theme.palette.accent.main, theme.palette.primary.main]
    const BoxStyle={
        display:"flex",
        flexDirection:"column",
        // width:400,
        height:270,
        // backgroundColor: theme.palette.accent.main

    }
    function customFormatter(seriesName, opts){
    const dataPointIndex = opts.seriesIndex; // Index of the current data point (category)
    const value = opts.w.globals.series[0][dataPointIndex]; // Retrieve the value for the current data point
    
    // return [seriesName, , value,"M"]; 
    const jsxString = renderToString(
        <Box marginX={1}>
            <Typography sx={{fontWeight: "800"}} >{seriesName}</Typography>
            <Typography>{value} M</Typography>
        </Box>
    );

    return `${seriesName}: ${value}`;
   }

    const data =[{
        name: 'Sales',
        data: [1135, 635, 435, 1143 ],
      },]
      const [options, setOptions] = useState({
        chart: {
          type: "bar",
          stackType: "100%",
          // height: 350
          toolbar: {
            show: false,
          }
        },
      
        plotOptions:{
            bar:{
                columnWidth: '15%',
                endingShape: 'rounded',
                borderRadius: 6,
                distributed: true,
                fontSize:8
            }
        },
        grid:{
            show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: ["Brand 1", "Brand 2", "Brand 3", "Brand 4"],
        },
        yaxis:{
         
            labels:{
                show:false,
            }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          offsetX: 5,
          offsetY: 5,
          markers:{
            radius:25
          },
        // formatter: customFormatter,
        },
      });
return(
  <>
    <Typography fontWeight={theme.typography.fontWeightBold} p={1}>
    Sales
    </Typography>
    <ReactApexChart
      options={options}
      series={data}
      type="bar"
      height={180}
      // width={400}

    />
  </>
)
}
export default SalesColumnChart;