import { arrayMove } from "@dnd-kit/sortable";

export const onDragEndHandler = (event, setUsers, users) => {
  const { active, over } = event;
  if (!over) return;
  if (active.id === over.id) return;

  const oldIndex = users.findIndex((user) => user.id === active.id);
  const newIndex = users.findIndex((user) => user.id === over.id);

  setUsers((prevUsers) => arrayMove(prevUsers, oldIndex, newIndex));
};
