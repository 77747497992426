import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function UnknownMixChart({ data, names, minValue, maxValue }) {
  //   const getYAxisFormatter = (val) => {
  //     if(data.name === "Base Market Share"){
  //       return val;
  //     }
  //     else{
  //     const formattedAmount = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(val);
  //     return formattedAmount;
  //     }
  // };
  // useEffect(()=>{
  //   console.log(data)
  //   console.log(names)
  // })
  const [chartReady, setChartReady] = useState(false);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const processedSeries = data.map((item) => ({
        name: item.name,
        type: item.type || "line",
        data: item.data || [],
      }));
      setSeriesData(processedSeries);

      setChartReady(true);
    }
  }, [data]);
  const getYAxisFormatter = (val) => {
    if (data && data[0] && data[0].name === "Base Market Share") {
      return val;
    } else {
      const formattedAmount = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(val);
      return formattedAmount;
    }
  };

  const series = data;
  const options = {
    responsive: [
      {
        breakpoint: 300, // Width in pixels
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
      {
        breakpoint: 3900, // Width in pixels
        options: {
          chart: {},
          legend: {
            position: "top",
          },
        },
      },
    ],
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        // endingShape: 'rounded',
        // borderRadius: 20,
        fontSize: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      // width: [1, 1, 4]
      show: false,
    },
    markers: {
      size: 6,
      show: true,
    },
    xaxis: {
      categories: names,
    },
    yaxis: [
      {
        seriesName: "Total",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
          formatter: getYAxisFormatter,
        },

        tooltip: {
          enabled: true,
        },
        min: minValue,
        max: maxValue,
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    chartReady && (
      <ReactApexChart
        options={options}
        series={data}
        type="line"
        height={220}
        width={300}
      />
    )
  );
}
export default UnknownMixChart;
