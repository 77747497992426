import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";

function PriceLaddersTable() {
  const [tableData, setTableData] = useState(null); // Holds API response data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch data from API
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "http://127.0.0.1:5000/market_overview/ppa",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              project_id: 1, // Replace with the actual project_id as required
              manufacturer: "AFIA", // Adjust as needed
              period: "quarter", // Adjust as needed
              hierarchy: "size_bracket",
              target_1: 2023,
              target_2: 3,
              area: null,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log(result);
        setTableData(result);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // Render stacked bars with percentages
  const renderStackedBars = (data, totalVolume) => (
    <Box display="flex" width="100%" alignItems="center" height="30px">
      {data.map((item, index) => {
        const normalizedWidth = (item.value / totalVolume) * 100; // Normalize based on total volume
        return (
          <Box
            key={index}
            position="relative"
            flex={`0 0 ${normalizedWidth}%`} // Set width proportional to value
            bgcolor={item.color}
            height="100%"
            marginRight="1px"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            title={`${item.name}: ${item.value.toFixed(1)}%`}
          >
            {item.value > 0 &&
              normalizedWidth > 5 && ( // Show text only if width > 5%
                <Typography
                  variant="caption"
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  {item.value.toFixed(1)}%
                </Typography>
              )}
          </Box>
        );
      })}
    </Box>
  );

  // Render the table

  const renderTable = () => {
    if (!tableData) return null;

    const { Size_Bracket, ...columns } = tableData;

    // Prepare data for each row
    const rows = Size_Bracket.map((sizeBracket, index) => {
      const volumeShare = columns["Volume Share Market"][index];
      const brands = Object.keys(columns).filter(
        (key) => key !== "Size_Bracket" && key !== "Volume Share Market"
      );

      const volumeData = brands.map((brand) => ({
        name: brand,
        value: columns[brand][index],
        color: getBrandColor(brand), // Use consistent brand colors
      }));

      return {
        price: sizeBracket,
        volume: volumeData,
        totalVolume: volumeShare, // Total for normalization
      };
    });

    return (
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index} sx={{ height: "24px" }}>
            <TableCell
              sx={{
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              {row.price}
            </TableCell>
            <TableCell
              sx={{
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              {renderStackedBars(row.volume, row.totalVolume)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  // Assign consistent colors to brands
  const getBrandColor = (brand) => {
    const brandColors = {
      AFIA: "#1E90FF",
      DALAL: "#FFA07A",
      "AL ARABI": "#6ffb6f",
      HALAH: "#ffa51f",
      Others: "#8A2BE2",
      NOOR: "#20B2AA",
      SUNNY: "#FF6347",
      HAYAT: "#FFD700",
      SHUROOQ: "#B22222",
    };
    return brandColors[brand] || "#D3D3D3"; // Default color for unknown brands
  };
  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Box width="100%">
      {" "}
      {/* Ensures the entire box takes full width */}
      <Typography variant="h6" component="h2" gutterBottom align="center">
        Price Ladders and Volume Share Table
      </Typography>
      {/* Table */}
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table style={{ tableLayout: "fixed", width: "100%" }}>
          {" "}
          {/* Ensure fixed layout */}
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  width: "20%", // Allocate width proportionally
                }}
              >
                Price Ladders (EGP)
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  width: "80%", // Allocate remaining width for the graph
                }}
              >
                Volume Share Market (%)
              </TableCell>
            </TableRow>
          </TableHead>
          {renderTable()}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PriceLaddersTable;
