import { Paper,Box, Container, Typography   } from "@mui/material";
import React, { useEffect, useState } from "react";
import { renderToString } from 'react-dom/server';
import ReactApexChart from "react-apexcharts";
import { theme } from "../../../Theme";
import { alpha } from "@mui/material";
import InfoBar from "../../Partials/Infobar/InfoBar";

function ThreePieChart({Title, Sign, Color, Sales, Names}){
   const [data, setData] = useState([0])

    const BoxStyle={
        display:"flex",
        flexDirection:"column",
        // width:250,
        height:270,
        paddingBottom: 2,
        // backgroundColor: theme.palette.accent.main

    }

    useEffect(()=>{
      console.log(Sales, Names)
      setData(Sales)
      setOptions({
        chart: {
          type: "pie",
          height: 250,
      },
      
      colors:[Color, alpha(Color,0.6), alpha(Color,0.4), alpha(Color, 0.2)],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      labels: Names,
      dataLabels:{
          enabled: false,
      },
      stroke:{
          show: false,
      },
 
      fill: {
        opacity: 1,
      },
      
      legend: {
        position: "bottom",
        offsetX: 5,
        offsetY: 5,
        markers:{
          radius:25
        },
      //   formatter: customFormatter,
      },
      })
    },[Sales])
    function customFormatter(seriesName, opts){
        const dataPointIndex = opts.seriesIndex;
        const value = opts.w.globals.series[dataPointIndex];
        const total = opts.w.config.series.reduce((acc, curr) => acc + curr, 0);
        const percentage = ((value / total) * 100).toFixed(1); // Calculate percentage
    
        return `${seriesName}: ${percentage}${Sign}`;
    }
    const [options, setOptions] = useState({
        chart: {
            type: "pie",
            height: 250,
        },
        
        colors:[Color, alpha(Color,0.6), alpha(Color,0.3)],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        labels: ["Market", "Brand 1","Brand 2"],
        dataLabels:{
            enabled: false,
        },
        stroke:{
            show: false,
        },
   
        fill: {
          opacity: 1,
        },
        
        legend: {
          position: "bottom",
          offsetX: 5,
          offsetY: 5,
          markers:{
            radius:25
          },
        formatter: customFormatter,
        },
    });
   
    return (

      <>
         <Typography alignItems={'center'} fontWeight={theme.typography.fontWeightBold} px={1}>
            {Title}
        </Typography>
            <ReactApexChart
            options={options}
            series={data}
            type="pie"
            height="100%" 
            />
      </>
      );

}
export default ThreePieChart;