import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Typography,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { getUrl } from "src/Utils/Common";

function PriceElasticity() {
  const [chartSeries, setChartSeries] = useState([]); // Holds chart data for multiple SKUs
  const [xAxisCategories, setXAxisCategories] = useState([]); // Holds the x-axis categories
  const [skus, setSkus] = useState([]); // List of available SKUs
  const [selectedSkus, setSelectedSkus] = useState([]); // List of selected SKUs
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch SKUs from the server
  useEffect(() => {
    async function fetchSkus() {
      try {
        const response = await fetch(
          `${getUrl()}/sim/get_sku_by_project_name?project_name=newsavola2`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const skusData = await response.json();
        setSkus(skusData.skus || []);
      } catch (err) {
        setError(err.message);
      }
    }

    fetchSkus();
  }, []);

  // Fetch Elasticity Data for selected SKUs
  const fetchElasticityData = async () => {
    if (!selectedSkus.length) {
      setError("Please select at least one SKU.");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await fetch(
        `${getUrl()}/sim/elasticity_chart`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            project_name: "newsavola2",
            skus_array: selectedSkus,
            sku: 5,
            start: -0.5,
            end: 0.5,
            step: 0.1,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      // Process the data for chart
      const categories = Object.keys(data[selectedSkus[0]] || {}).map((key) =>
        parseFloat(key).toFixed(1)
      );
      setXAxisCategories(categories);

      const series = selectedSkus.map((sku) => ({
        name: sku,
        data: Object.values(data[sku] || {}).map((value) => parseFloat(value)),
      }));

      setChartSeries(series);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: { show: false },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: xAxisCategories,
      title: {
        text: "Price Elasticity",
        style: { fontSize: "14px", fontWeight: "bold" },
      },
    },
    yaxis: {
      title: {
        text: "% Change in Volume Share",
        style: { fontSize: "14px", fontWeight: "bold" },
      },
      labels: {
        formatter: function(val) {
          return val.toFixed(0);
        }
      }
    
    },
    markers: { size: 5 },
    legend: { position: "top" },
    tooltip: { shared: true, intersect: false },
    colors: ["#1E90FF", "#FF6347", "#32CD32", "#FFD700"], // Colors for multiple SKUs
  };

  return (
    <Box>
      <Typography variant="h5" component="h3" gutterBottom>
        Price Elasticity
      </Typography>

      <Autocomplete
        multiple
        options={skus}
        value={selectedSkus}
        onChange={(event, newValue) => setSelectedSkus(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select SKUs"
            variant="outlined"
            size="small"
            placeholder="Search or select SKUs"
          />
        )}
        sx={{ width: 400, marginBottom: 3 }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={fetchElasticityData}
        disabled={loading}
        sx={{ marginBottom: 3 }}
      >
        Fetch Data
      </Button>

      {error && <Typography color="error">{error}</Typography>}

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          width={500}
          height={260}
        />
      )}

      {/* <Box width="60%">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    padding: "4px 8px",
                  }}
                >
                  Price Elasticity
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    padding: "4px 8px",
                  }}
                >
                  % Change in Volume Share
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index} sx={{ height: "24px" }}>
                  <TableCell
                    sx={{
                      padding: "4px 8px",
                      fontSize: "12px",
                    }}
                  >
                    {row.elasticity}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "4px 8px",
                      fontSize: "12px",
                    }}
                  >
                    {row.value.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </Box>
  );
}

export default PriceElasticity;
