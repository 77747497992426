import React, { useState } from "react";
import { Box, TextField, Button, Typography, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserProject } from "../../Redux/userPojectSlice/userProjectSlice";
import Navbar from "src/Components/Partials/Navbar/Navbar_2";
import { getUrl } from "src/Utils/Common";

const UserProjectSelection = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem("userId"); // Get the token from local storage
      if (!token) {
        alert("Authentication token not found. Please log in again.");
        return;
      }

      const response = await fetch(`${getUrl()}/auth/get_projects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, // Pass the token in the headers
          'x-cors-api-key': 'temp_8c8f91f122df0ef9daa49a6701f13f76'
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          alert("Unauthorized access. Please log in again.");
          navigate("/login");
        } else {
          throw new Error("Failed to fetch projects");
        }
      }

      const result = await response.json();

      // Set the projects state with the response data
      const formattedProjects = result.project_ids.map((id, index) => ({
        id,
        name: result.project_names[index],
      }));
      setProjects(formattedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError("Failed to fetch projects. Please try again.");
    }
  };

  const handleProjectSelection = () => {
    if (selectedProjectId) {
      // Save the selected projectId to local storage
      localStorage.setItem("projectId", selectedProjectId);

      // Dispatch to Redux
      dispatch(setUserProject({ projectId: parseInt(selectedProjectId, 10) }));

      // Redirect to dashboard
      navigate("/dashboard");
    } else {
      alert("Please select a project.");
    }
  };

  return (
    <>
      <Navbar />

      <Box
        sx={{
          width: 400,
          margin: "auto",
          marginTop: 10,
          padding: 4,
          borderRadius: 2,
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            fontWeight: "bold",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          Select a Project
        </Typography>
        {error && (
          <Typography color="error" sx={{ marginBottom: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={fetchProjects}
          sx={{
            marginBottom: 2,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Fetch Projects
        </Button>
        {projects.length > 0 && (
          <TextField
            select
            fullWidth
            label="Select Project"
            value={selectedProjectId}
            onChange={(e) => setSelectedProjectId(e.target.value)}
            sx={{ marginBottom: 2 }}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleProjectSelection}
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default UserProjectSelection;
