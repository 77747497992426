import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") || null,
  projectId: localStorage.getItem("projectId") || null,
};

const userProjectSlice = createSlice({
  name: "userProject",
  initialState,
  reducers: {
    setUserProject: (state, action) => {
      const { userId, projectId } = action.payload;
      state.userId = userId;
      state.projectId = projectId;
      // Save to localStorage
      // localStorage.setItem("userId", userId);
      localStorage.setItem("projectId", projectId);
    },
    clearUserProject: (state) => {
      state.userId = null;
      state.projectId = null;
      localStorage.removeItem("userId");
      localStorage.removeItem("projectId");
    },
  },
});

export const { setUserProject, clearUserProject } = userProjectSlice.actions;
export default userProjectSlice.reducer;
