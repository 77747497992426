import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function Progressbar({ progress, color }) {
  // Ensure progress value is between 0 and 100

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        variant="determinate"
        value={progress}
sx={{ bgcolor: '#f0f0f0', // Background color of the track
'& .MuiLinearProgress-bar': {
  bgcolor: color, // Color of the progress bar
}}}
      />
    </Box>
  );
}

export default Progressbar;
