import React,{useState} from "react";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { theme } from "../../../Theme";

const CollapsibleList = ({ title, icon, items, openInitially = false, drawerOpen, setTitle}) => {
  const [open, setOpen] = React.useState(openInitially);

  const handleClick = () => {
    setOpen(!open);
  };
  

  const ListTitleText = {
    fontSize: theme.typography.fontSize.xsmall,
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    display: drawerOpen? "auto": "none"
  };

  const ListTitleIcon = {
    color: theme.palette.accent.main,
    minWidth: 0,
    mr:1,
    justifyContent: 'center',
  };
  const ExpandIcon={
    color: theme.palette.accent.main,
  }

  return (
    <>
      <ListItemButton sx={{ justifyContent: open ? 'initial' : 'center' }} onClick={handleClick}>
        <ListItemIcon sx={ListTitleIcon}>{icon}</ListItemIcon>
        <ListItemText sx={ListTitleText} primary={title} />
        {open ? <ExpandLess sx={ExpandIcon}/> : <ExpandMore sx={ExpandIcon}/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItemButton
              key={index}
              sx={{
                bgcolor: item.activeCondition === item.active ? theme.palette.primary.main : theme.palette.secondary.main,
                borderRadius: "25px",
                // minHeight: 48,
                height:36,
                mx:0.5,
                // width:200,
                justifyContent: open ? 'initial' : 'center',
                // px: 2.5,
                ":hover":{
                  backgroundColor: item.activeCondition === item.active && theme.palette.primary.main
                }
              }}
              onClick={() => {item.onClick(item.subPath); setTitle(item.text);}}
              disabled={item.disabled}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mx:1,
                  justifyContent: 'center',
                  color: item.activeCondition === item.active ? theme.palette.secondary.main : theme.palette.primary.dark,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText sx={{ fontSize: theme.typography.fontSize.xxsmall,
                  color: item.activeCondition === item.active ? theme.palette.secondary.main : theme.palette.primary.dark,
                  display: drawerOpen? "auto": "none"
                 }} primary={item.text} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleList;
