import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { alpha } from "@mui/material";
function ColoredBox({ number, Color }) {
  const BoxStyle = {
    border: `1px solid ${Color}`, // Adding border style
    fontWeight: "bold",
    color: Color,
    backgroundColor: alpha(Color, 0.1),
    width: "50px",
    height: "24px",
    borderRadius: 9,
    margin: "auto",
    alignItems: "Center",
    display: "flex",
    justifyContent: "Center",
    marginY: "5px",
  };

  return (
    <Container style={{ marginTop: "5px" }}>
      <Box style={BoxStyle}>{number}</Box>
    </Container>
  );
}
export default ColoredBox;
