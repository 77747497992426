import React, { useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import AddSection from "../../Components/Partials/AddSection/AddSection";
import { useCustomSensors } from "../../DragAndDrop/DragHandlers";
import { SortableChart } from "../StatisticsTest/components/SortableChart";
import { theme } from "../../Theme";
import SourceShareTable from "../../Components/Tables/SourceShareTable/SourceShareTable";
import BrandShare from "../../Components/Tables/BrandShareTable/BrandShare";
import UnknownMixChart from "../../Components/Charts/UnknownMixChart/UnknownMixChart";
import CannibalizationTable from "src/Components/Tables/CannibalizationTable/CannibalizationTable";
import ShelfPriceGraph from "../../Components/Charts/ShelfPriceGraph/ShelfPriceGraph";
import PriceElasticity from "../../Components/Charts/PriceElasticity/PriceElasticity";
import PriceLaddersTable from "src/Components/Tables/PriceLaddersTable/priceLaddersTable";

const data = [
  {
    id: "UnknownMixChart",
    component: <UnknownMixChart />,
    name: "Unknown Mix",
    xs: 12,
    md: 6,
  },
];

function PricingAndArchitecture() {
  const [activeId, setActiveId] = useState(null);
  const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);

  const [items, setItems] = useState({
    mainDash: [
      {
        id: "SourceShare",
        component: <SourceShareTable />,
        name: "Source Share",
        xs: 8,
        md: 6,
      },
      // {
      //   id: "BrandShare",
      //   component: <BrandShare />,
      //   name: "Brand Share",
      //   xs: 12,
      //   md: 8,
      // },
      // {
      //   id: "ShelfPriceGraph",
      //   component: <ShelfPriceGraph />,
      //   name: "Shelf Price Graph",
      //   xs: 12,
      //   md: 12,
      // },
      {
        id: "PriceElasticity",
        component: <PriceElasticity />,
        name: "Price Elasticity",
        xs: 8,
        md: 6,
      },
      {
        id: "CannibalizationTable",
        component: <CannibalizationTable />,
        name: "Cannibalization",
        xs: 12,
        md: 12,
      },
      // {
      //   id: "PriceLaddersTable",
      //   component: <PriceLaddersTable />,
      //   name: "Price Ladders",
      //   xs: 12,
      //   md: 12,
      // },
    ],
    addSection: [...data],
  });

  const sensors = useCustomSensors();

  const handleDragStart = (event) => setActiveId(event.active.id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Ensure a valid drop target
    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }

    setItems((prev) => {
      const fromMainDash = prev.mainDash.find((item) => item.id === active.id);
      const fromAddSection = prev.addSection.find(
        (item) => item.id === active.id
      );

      if (fromMainDash) {
        // Reorder within mainDash
        const updatedMainDash = [...prev.mainDash];
        const draggedItemIndex = updatedMainDash.findIndex(
          (i) => i.id === active.id
        );
        const overIndex = updatedMainDash.findIndex((i) => i.id === over.id);

        // Move item
        const [draggedItem] = updatedMainDash.splice(draggedItemIndex, 1);
        updatedMainDash.splice(overIndex, 0, draggedItem);

        return {
          ...prev,
          mainDash: updatedMainDash,
        };
      }

      if (fromAddSection) {
        // Move from addSection to mainDash
        const updatedAddSection = prev.addSection.filter(
          (i) => i.id !== active.id
        );
        const updatedMainDash = [...prev.mainDash, fromAddSection];

        return {
          mainDash: updatedMainDash,
          addSection: updatedAddSection,
        };
      }

      return prev;
    });

    setActiveId(null);
  };

  const toggleAddSection = () => setIsAddSectionOpen((prev) => !prev);

  const handleAddComponent = (id) => {
    setItems((prev) => {
      const item = prev.addSection.find((i) => i.id === id);
      if (!item) return prev;

      const updatedAddSection = prev.addSection.filter((i) => i.id !== id);
      const updatedMainDash = [...prev.mainDash, item];

      return {
        mainDash: updatedMainDash,
        addSection: updatedAddSection,
      };
    });
    setIsAddSectionOpen(false);
  };

  const TitleStyle = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize.medium,
    paddingY: 1,
    color: theme.palette.primary.main,
  };

  const ComponentBar = {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const CloseButton = {
    alignSelf: "flex-end",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  };
  const RemoveComponent = (id) => {
    setItems((prev) => {
      const item = prev.mainDash.find((i) => i.id === id);
      const updatedMainDash = prev.mainDash.filter((i) => i.id !== id);
      const updatedAddSection = [...prev.addSection, item];

      return {
        mainDash: updatedMainDash,
        addSection: updatedAddSection,
      };
    });
  };
  return (
    <Box>
      <AddSection sx={{ m: 2 }} toggleOpen={toggleAddSection} />
      {isAddSectionOpen && (
        <Box component={Paper} sx={ComponentBar}>
          <CloseOutlinedIcon sx={CloseButton} onClick={toggleAddSection} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {items.addSection.map((item) => (
              <Box
                key={item.id}
                onClick={() => handleAddComponent(item.id)}
                sx={{
                  cursor: "pointer",
                  padding: 2,
                  border: "1px solid #ccc",
                  margin: 1,
                  borderRadius: "4px",
                }}
              >
                <Typography>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items.mainDash} strategy={rectSortingStrategy}>
          <Typography sx={TitleStyle}>Pricing & Architecture</Typography>
          <Grid container spacing={2}>
            {items.mainDash.map((item) => (
              <Grid item xs={item.xs} sm={6} md={item.md} key={item.id}>
                <SortableChart
                  RemoveComponent={() => RemoveComponent(item.id)}
                  user={item}
                />
              </Grid>
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </Box>
  );
}

export default PricingAndArchitecture;
